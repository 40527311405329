import React, { Component } from 'react';
import { UIButton } from '../Buttons/UIButton';
import '../CSS/Buttons.css';

export class SoundButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            soundEnabled: window.Environment.audioManager.soundEnabled
        }

        this.toggleSound = this.toggleSound.bind(this);
    }

    toggleSound() {
        let soundEnabled = window.Environment.audioManager.toggleSound();

        this.setState({
            soundEnabled
        });
    }

    render() {
        return (
            <UIButton
                onClick={this.toggleSound}
            >
                <img
                    src={"https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/icons/sound_" + (this.state.soundEnabled ? "3" : "0") + ".png"}
                    className="ui-button-sound-icon"
                />
                SOUND {this.state.soundEnabled ? "ON" : "OFF"}
            </UIButton>
        );
    }
}