import React, { Component } from 'react';
import '../CSS/OtherUI.css';

export class FullscreenSpinner extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            visible: false
        }
    }

    show() {
        this.setState({
            visible: true
        });
    }

    hide() {
        this.setState({
            visible: false
        });
    }

    render() {
        return (
            <div
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "url(/textures/login_back.png)",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: "center",
                    zIndex: "500",
                    transition: "opacity 1s ease-in-out, visibility 1s ease-in-out",
                    opacity: this.props.visible ? "1" : "0",
                    visibility: this.props.visible ? "visible" : "hidden",
                    pointerEvents: this.props.visible ? "auto" : "none",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.6)",
                        pointerEvents: this.props.visible ? "auto" : "none",
                    }}
                >
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        );
    }
}