import { eraseCookie } from '../Utils/Cookies'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import 'cross-fetch/polyfill';

// More Cognito functions like Login, but without the need to change login state
export class AuthService {

    static GetUserPool() {
        return this.UserPool = {
            UserPoolId: window.AppSettings.aws_cognito_user_pool_id,
            ClientId: window.AppSettings.aws_cognito_client_id,
        }
    }

    static GetToken() {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var cognitoUser = userPool.getCurrentUser();
        var token = "";
        cognitoUser.getSession(function (err, session) {
            if (err) {
                AuthService.Logout();
            } else {
                //return session.accessToken.jwtToken;
                token = session.idToken.jwtToken;
            }
        });
        return token;
    }

    static GetUsername() {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var cognitoUser = userPool.getCurrentUser();

        return cognitoUser.getUsername();
    }

    static Logout(callback) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var cognitoUser = userPool.getCurrentUser();
        cognitoUser.signOut(); // cognitoUser.signOut(callback) + invalidate all tokens
        eraseCookie("RememberMe");

        if (callback) {
            callback();
        }

        window.location.href = "/";
    }
}