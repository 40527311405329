import React, { Component } from 'react';

export class DivLoginContainer extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <div className={`login-card-container ${this.props.right ? 'right' : 'left'}`}>
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}