import React from 'react';
import { CSS3DComponent } from './CSS3DComponent';
import * as THREE from 'three';
import '../CSS/WaypointIcon.css';
import { Button } from '../Buttons/Button';

export class WaypointIcon extends CSS3DComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);

        this.waypointClick = React.createRef();

        this.mesh = new THREE.Mesh(); // To use just for the "lookAt" function
    }

    componentDidMount() {
        super.componentDidMount();
        this.mesh.position.set(this.cssComponent.position.x, this.cssComponent.position.y, this.cssComponent.position.z);
    }

    onClick() {
        window.Environment.selectCubemap(this.props.name);
    }

    subrender() {
        this.mesh.lookAt(new THREE.Vector3(this.props.currentCubemap.position.x, this.props.currentCubemap.position.y, this.props.currentCubemap.position.z));
        let transform = `rotateX(${this.mesh.rotation.x + Math.PI / 2}rad) rotateY(${this.mesh.rotation.y}rad) rotateZ(${this.mesh.rotation.z}rad) translateY(-25px)`; //@fede: translate up a lil bit

        return (
            <div className="waypoint-icon">
                <Button
                    ref={this.waypointClick} className="waypoint-icon-click" onClick={this.onClick}
                    style={{
                        transform: transform
                    }}
                >
                </Button>
                <div className="waypoint-icon-shadow"></div>
                <div className="waypoint-icon-particle" style={{ animationDelay: "0.00s" }}></div>
                <div className="waypoint-icon-particle" style={{ animationDelay: "0.33s" }}></div>
                <div className="waypoint-icon-particle" style={{ animationDelay: "0.66s" }}></div>
            </div>
        )
    }

    //render() {
    //    return (
    //        <div ref={this.divRef} className="floor-icon">
    //            <div className="floor-icon-shadow"></div>
    //            <div className="floor-icon-particle" style={{ animationDelay: "0.00s" }}></div>
    //            <div className="floor-icon-particle" style={{ animationDelay: "0.33s" }}></div>
    //            <div className="floor-icon-particle" style={{ animationDelay: "0.66s" }}></div>
    //            <div className="floor-icon-click" onPointerDown={this.onClick}></div>
    //        </div>
    //    )
    //}
}