import { AuthService } from "../../Login/AuthService";
import { Products_API_DATA, Resources_API, Resources_API_Data, Sessions_API_Data } from "../Resources_API";

export class BPZ_API extends Resources_API  {
    constructor() {
        super();

        this.apiUrl = 'https://0tiuc13rl2.execute-api.ap-northeast-1.amazonaws.com/v1';
    }

    async fetchResources() {
        // Fetch resources
        const response = await fetch(`${this.apiUrl}/resources`, {
            method: 'GET',
            headers: {
                'Authorization': `${AuthService.GetToken()}`,
                'Accept': 'application/json; charset=utf-8',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        })
            .then(response => response.json());

        console.log(response);

        // Formatted data to return
        let apiData = new Resources_API_Data();

        // Format
        for (let i = 0; i < response.resources.length; i++) {
            let r = response.resources[i];

            // Get resource extension (the extension field is not reliable for now)
            let extension = r.url.split('.').pop();
            switch (extension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'bmp':
                    apiData.addImagePoster(r.resource_code, r.url);
                    break;
                case 'mp4':
                case 'avi':
                    apiData.addVideoPoster(r.resource_code, r.url);
                    break;
                default:
            }
        }

        // Add products here for now
        apiData.addProduct(
            "fridge",
            "Fridge",
            [
                'https://www.homeappliances.hitachi.com/products/refrigerators/item/PLR-V610PRU7_03.jpg',
                'https://www.homeappliances.hitachi.com/products/refrigerators/item/PLR-V610PRU7_01.jpg',
                'https://www.homeappliances.hitachi.com/products/refrigerators/item/PLR-V610PRU7_04.jpg',
                'https://www.homeappliances.hitachi.com/products/refrigerators/item/PLR-V610PRU7_05.jpg',
            ],
            [
                '#333',
                'silver',
                'white',
                'beige'
            ],
            "It's a fridge"
        );

        return apiData;
    }

    async fetchSessions() {
        // TODO

        // Fetch resources
        const response = await fetch(`/api_example/sessions.json`, {
            method: 'GET',
            cache: "no-cache"
        })
            .then(response => response.json());

        // Formatted data to return
        let apiData = new Sessions_API_Data();

        // Format
        for (let i = 0; response.sessions && i < response.sessions.length; i++) {
            let r = response.sessions[i];

            apiData.addSession(r.id, r.timeStart, r.timeEnd, r.thumbnail_url, r.title, r.internal_title, r.description, r.video_url, r.speakers);
        }

        return apiData;
    }

    makeReservation(id) {
        const token = AuthService.GetToken();

        fetch(`${this.apiUrl}/contact_reservations`,
            {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "booth_id": id
                })
            })
            .then(res => res.json())
            .then((result) => {
                console.log(result);
            })
            .catch(error => {
                alert("Could not make reservation");
            });
    }

    async getReservationMeetingTitle(id) {
        const token = AuthService.GetToken();

        let reservations = [];

        await fetch(`${this.apiUrl}/contact_reservations`,
            {
                method: 'GET',
                headers: !token ? {} : {
                    'Authorization': token,
                },
            })
            .then(res => res.json())
            .then((result) => {
                reservations = result.contact_reservations
                console.log(result);
            })
            .catch(error => {
                alert("Could not make reservation");
            });

        for (let reservation of reservations) {
            if (reservation.booth.booth_id == id) {
                return reservation.meeting_title;
            }
        }

        return null;
    }
}