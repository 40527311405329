import React from 'react';
import { PopupContainer } from './PopupContainer';
import '../CSS/PopupBooth.css';
import { CloseButton } from '../CSS3D/Menus/CloseButton';

export class PopupVideo extends PopupContainer {
    constructor(props) {
        super(props);

        this.video = React.createRef();
    }

    async onShow() {
        this.video.current.play();
    }

    onHide() {
        this.video.current.pause();
    }

    subrender() {
        return (
            <div style={{
                position: "absolute",
                width: "70%",
                height: "70%",
                left: "15%",
                top: "15%",
            }}>
                <CloseButton onClick={this.hide} />
                <video
                    ref={this.video}
                    width="100%"
                    height="100%"
                    playsInline
                    controls
                    style={{
                        background: "black",
                        objectFit: "contain"
                    }}>
                    <source src={this.props.url} type="video/mp4" />
                </video>
            </div>
        );
    }
}