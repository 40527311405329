import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DivLoginContainer } from './DivLoginContainer';
import { DivLogin } from './DivLogin';
import { DivError } from './DivError';

export class CodePage extends Component
{
    constructor(props)
    {
        super(props);

        this.props = props;

        this.state = {
            step: 1,
            processing: false,
            errorShow: false
        }

        this.GetCode = this.GetCode.bind(this);
        this.SendCode = this.SendCode.bind(this);
    }

    GetCode() {
        this.setState({
            processing: true,
            errorShow: false
        });

        let username = document.getElementById("input_email").value;

        this.props.login.ResendConfirmCode(
            username,
            function () {
                this.setState({
                    step: 2,
                    processing: false
                });
            }.bind(this),
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage,
                });
                this.refs.getcode.scrollToTop();
            }.bind(this)
        );
    }

    SendCode() {
        this.setState({
            processing: true,
            errorShow: false
        });

        let username = document.getElementById("input_email").value;
        let code = document.getElementById("input_code").value;

        this.props.login.ConfirmCode(
            username,
            code,
            function (result) {
                this.setState({
                    step: 3,
                    processing: false
                });
            }.bind(this),
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage,
                });
                this.refs.sendcode.scrollToTop();
            }.bind(this)
        );
    }

    render()
    {
        return (
            <DivLoginContainer>
                <DivLogin ref="getcode" title="RESEND CONFIRMATION CODE" step={1} currentstep={this.state.step} processing={this.state.processing}>
                    <DivError visible={this.state.errorShow} message={this.state.errorMessage} />
                    <div className="form-group">
                        <label htmlFor="input_email">メールアドレス</label>
                        <input disabled={this.state.step != 1 || this.state.processing} id="input_email" className="form-control" />
                    </div>
                    <br />
                    <br />
                    <div className="form-group">
                        <button type="button" onClick={this.GetCode} className="btn btn-primary btn-login">送る</button>
                    </div>
                    <br />
                    <div className="text-center">
                        <Link class="login-clickable-link" to="/">ログインへ戻る</Link>
                    </div>
                </DivLogin>

                <DivLogin ref="sendcode" title="RESEND CONFIRMATION CODE" step={2} currentstep={this.state.step} processing={this.state.processing}>
                    <DivError visible={this.state.errorShow} message={this.state.errorMessage} />
                    <p className="login-description">
                        メールで受け取ったコードをここに入力してください
                    </p>
                    <br />
                    <div className="form-group">
                        <label htmlFor="input_code">コード</label>
                        <input disabled={this.state.step != 2 || this.state.processing} id="input_code" className="form-control" />
                    </div>
                    <br />
                    <br />
                    <div className="form-group">
                        <button type="button" onClick={this.SendCode} className="btn btn-primary btn-login">送る</button>
                    </div>
                    <br />
                    <div className="text-center">
                        <Link class="login-clickable-link" to="/">ログインへ戻る</Link>
                    </div>
                </DivLogin>

                <DivLogin title="RESEND CONFIRMATION CODE" step={3} currentstep={this.state.step} processing={this.state.processing}>
                    <p className="login-description">
                        登録が完了しました。
                        <br />
                        今すぐログインできます。
                    </p>
                    <br />
                    <div className="text-center">
                        <Link class="login-clickable-link" to="/">ログインへ戻る</Link>
                    </div>
                </DivLogin>
            </DivLoginContainer>
        );
    }
}
