import React, { Component } from 'react';
import { InnerMenuButton } from '../Buttons/InnerMenuButton';
import '../CSS/FloorsMenu.css';
import '../CSS/Buttons.css';

export class FloorsMenu extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        let visible = this.props.floors.visible_on.includes(this.props.currentCubemap.name);

        return (
            <div
                className="floors-menu-container"
                style={{
                    opacity: visible ? "1" : "0",
                    visibility: visible ? "visible" : "hidden"
                }}
            >
                {
                    this.props.floors.locations.map((e, i) =>
                        <div className="floors-menu-container-entry">
                            <div className="floors-menu-floor-number">
                                {this.props.floors.locations.length-i}F
                            </div>
                            <InnerMenuButton key={i} onClick={() => window.Environment.selectCubemap(e[1], true)}>
                                {e[0]}
                            </InnerMenuButton>
                        </div>
                    )
                }
            </div>
        );
    }
}