import React, { Component } from 'react';

export class DivError extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <div className="overflow-hidden">
                <div className="text-danger" style={{
                    transform: `translateY(${this.props.visible ? "0" : "100%"})`,
                    transition: "transform 250ms ease"
                }}>
                    {this.props.message ? this.props.message : "　"}
                </div>
            </div>
        );
    }
}