import React, { Component } from 'react';
import { PopupContainer } from './PopupContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompress, faExpand, faGear, faMessage, faMicrophone, faMicrophoneSlash, faPaperPlane, faPhoneSlash, faVideoCamera, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from '../OtherUI/Spinner';
import { Message, Messages_API_Data } from '../../API/VideoChat_API';
import '../CSS/Chime.css';
import '../CSS/VideoChat.css';
import { Button } from '../Buttons/Button';

export class PopupVideoChat extends PopupContainer {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            messageList: undefined,
            minimized: false,
            selfCameraInView: true, // Only used for phone sizes
            disableTransition: false,
            disableMic: false,
            disableCamera: false,
            loading: false,
            error: false,
            errorMessage: '',
        }

        this.onResize = this.onResize.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.onPressEnter = this.onPressEnter.bind(this);
        this.toggleSelfVideoAndChat = this.toggleSelfVideoAndChat.bind(this);
        this.toggleMic = this.toggleMic.bind(this);
        this.toggleCamera = this.toggleCamera.bind(this);
        this.toggleMinimize = this.toggleMinimize.bind(this);
        this.appendMessage = this.appendMessage.bind(this);
        this.onReceiveMessage = this.onReceiveMessage.bind(this);

        this.videoStreams = [];

        // Create video
        const facingMode = "user";
        this.constraints = {
            audio: true,
            video: {
                facingMode
            }
        };

        // When we resize the screen to phone size, disable the transition effect on the selfcamera and chat containers for a while
        // to avoid some ugly effect
        this.transitionTimeout = 0;

        window.addEventListener('resize', this.onResize);
    }

    async onResize() {
        if (this.transitionTimeout > 0) {
            clearTimeout(this.transitionTimeout);
        }

        await this.setState({
            disableTransition: true
        });

        this.transitionTimeout = setTimeout(function () {
            this.setState({
                disableTransition: false
            });
            this.transitionTimeout = 0;
        }.bind(this), 10);
    }

    async onShow() {
        // Remove messages and start loading
        await this.setState({
            messageList: new Messages_API_Data(), // Empty for now
            loading: true,
            error: false,
            errorMessage: ''
        });

        // If this reference still exists
        if (this.refs.chatInput) {
        // Allow to send on enter
            this.refs.chatInput.addEventListener("keypress", this.onPressEnter);

            // Scroll down
            this.refs.chat.scrollTo(0, this.refs.chat.scrollHeight);
        }

        // Find meeting title
        let meetingTitle = await this.props.api.getReservationMeetingTitle(63);

        // Start meeting and see if it could connect
        let connectionStatus = await this.props.videoChatApi.join(meetingTitle, this.onReceiveMessage, this.refs.selfCamera, !this.state.disableCamera);

        // Could connect
        if (connectionStatus.ok) {
            await this.setState({
                loading: false, // Hide spinner
            });
        }
        // Could not connect: find out why
        else {
            //
            await this.setState({
                error: true,
                errorMessage: connectionStatus.errorMessage
            });
        }
    }

    onHide() {
        this.refs.chatInput.removeEventListener("keypress", this.onPressEnter);
        this.props.videoChatApi.leave();
    }

    onPressEnter(event) {
        if (event.key === "Enter") {
            this.onClickSend();
        }
    }

    async onClickSend() {
        if (this.refs.chatInput.value.length === 0) {
            return;
        }

        let message = new Message(
            this.state.messageList.messages.length + 1,
            new Date(),
            true,
            this.refs.chatInput.value
        );

        // Send messages to other person
        this.props.videoChatApi.sendMessage(message, this.appendMessage);

        // Clear input
        this.refs.chatInput.value = '';
    }

    async onReceiveMessage(text) {
        // Add new message to data
        let message = new Message(
            this.state.messageList.messages.length + 1,
            new Date(),
            false,
            text
        );

        this.state.messageList.addMessage(message);

        await this.forceUpdate();

        // Scroll down
        this.refs.chat.scrollTo(0, this.refs.chat.scrollHeight);
    }

    async appendMessage(message) {
        // Add new message to data
        this.state.messageList.addMessage(message);

        await this.forceUpdate();

        // Scroll down
        this.refs.chat.scrollTo(0, this.refs.chat.scrollHeight);
    }

    toggleSelfVideoAndChat() {
        this.setState({
            selfCameraInView: !this.state.selfCameraInView
        })
    }

    toggleMic() {
        this.setState({
            disableMic: !this.state.disableMic
        })
    }

    toggleMinimize() {
        this.setState({
            minimized: !this.state.minimized
        })
    }

    async toggleCamera() {
        await this.setState({
            disableCamera: !this.state.disableCamera
        });

        if (this.state.disableCamera) {
            this.props.videoChatApi.stopSelfCamera();
        }
        else {

            this.props.videoChatApi.startSelfCamera();
        }
    }

    // Override render since we have to make a very particular implementation
    render() {
        if (this.state.visible) {
            return (
                <>
                    <div style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "100",
                        opacity: this.state.fadeOut ? "0" : "1",
                        transition: "opacity 0.25s linear",
                        pointerEvents: "none",
                    }}>
                        {this.subrender(this.props)}
                    </div>
                </>
            );
        }
        else {
            return <></>;
        }
    }

    subrender() {
        return (
            <div className={`video-chat-container ${this.state.minimized ? 'video-chat-container-minimized' : ''}`}>
                <div className="video-chat-background">
                    {
                        // If it is loading or an error, show gray background
                        this.state.loading || this.state.error ?
                            <div className="video-chat-disabled-overlay"></div>
                            :
                            <></>
                    }
                    {
                        // No errors and still loading: show spinner
                        this.state.loading && !this.state.error ?
                            <div className="video-chat-spinner-container">
                                {
                                    <Spinner visible={true} />
                                }
                            </div>
                            :
                            <></>
                    }
                    {
                        // Error: show the it
                        this.state.error ?
                            <div className="video-chat-error-container">
                                <div className="video-chat-error-message">
                                    <div>
                                        {this.state.errorMessage}
                                    </div>
                                    <div>
                                        <Button onClick={this.hide}>
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    <div className="videocall-container">
                        <h3>
                            PLACEHOLDER
                        </h3>
                        <div className="videocall-controls-container">
                            <div className="videocall-controls">
                                <div className="button-call-generic" onClick={this.toggleMinimize}>
                                    <FontAwesomeIcon color="white" icon={this.state.minimized ? faExpand : faCompress} />
                                </div>
                                <div className={`button-call-generic ${this.state.disableMic ? "button-call-generic-red" : ""}`} onClick={this.toggleMic}>
                                    {
                                        <FontAwesomeIcon color="white" icon={this.state.disableMic ? faMicrophoneSlash : faMicrophone} />
                                    }
                                </div>
                                <div className="button-call-end" onClick={this.hide}>
                                    <FontAwesomeIcon color="white" icon={faPhoneSlash} />
                                </div>
                                <div className={`button-call-generic ${this.state.disableCamera ? "button-call-generic-red" : ""}`} onClick={this.toggleCamera}>
                                    <FontAwesomeIcon color="white" icon={this.state.disableCamera ? faVideoSlash : faVideoCamera} />
                                </div>
                                <div className="button-call-generic">
                                    <FontAwesomeIcon color="white" icon={faGear} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="selfvideo-chat-container">
                        <div className={`selfvideo-container ${this.state.selfCameraInView ? 'in-view' : ''} ${this.state.disableTransition ? 'disable-transition' : ''}`}>
                            <video
                                ref="selfCamera"
                                playsInline={true}
                                muted={true}
                                style={{
                                    transform: "scaleX(-1)", /*Mirrored*/
                                }}
                            >
                            </video>
                            <div className="button-toggle-selfvideo-chat" onClick={this.toggleSelfVideoAndChat}>
                                <FontAwesomeIcon color="white" icon={faMessage} />
                            </div>
                        </div>
                        <div className={`chat-container ${!this.state.selfCameraInView ? 'in-view' : ''} ${this.state.disableTransition ? 'disable-transition' : ''} ${this.state.error ? 'disabled' : ''}`}>
                            <div className="chat">
                                <div ref="chat" className="chat-messages-container">
                                    {
                                        this.state.messageList ?
                                            this.state.messageList.messages.map((e, i) => <MessageBubble key={i} me={e.me} message={e.message} />)
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                            <div className="chat-input-container">
                                <div className="button-toggle-selfvideo-chat" onClick={this.toggleSelfVideoAndChat}>
                                    <FontAwesomeIcon color="white" icon={faVideoCamera} />
                                </div>
                                <div className="chat-input">
                                    <input ref="chatInput" type="text" maxLength="500" />
                                </div>
                                <div className="chat-send-container">
                                    <div className="chat-send" onClick={this.onClickSend}>
                                        <FontAwesomeIcon color="white" icon={faPaperPlane} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class MessageBubble extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="chat-message-wrapper" style={{ textAlign: this.props.me ? "right" : "left" }}>
                <div className={`chat-message chat-message-${this.props.me ? "right" : "left"} text-that-needs-wrapping`}>
                    {this.props.message}
                    <div className={`chat-message-arrow-${this.props.me ? "right" : "left"}`}></div>
                </div>
            </div>
        );
    }
}