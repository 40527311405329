import { GetCookie, SetCookie } from "../Utils/Cookies";

export class AudioManager {
    constructor() {
        this.soundEnabled = GetCookie("AudioMuted") !== "muted";

        this.bgm = null;
        this.clickSound = null;
    }

    init(bgmUrl, clickSoundUrl) {
        this.bgm = new Audio();
        this.bgm.src = bgmUrl;
        this.bgm.loop = true;
        this.bgm.volume = 0.7;

        if (this.soundEnabled) {
            //this.bgm.play(); // Try and play on init
        }

        this.clickSound = new Audio();
        this.clickSound.src = clickSoundUrl;
    }

    toggleSound() {
        this.soundEnabled = !this.soundEnabled;

        // Toggle bgm and update cookie
        if (this.soundEnabled) {
            //this.bgm.play();
            SetCookie("AudioMuted", "no");
        }
        else {
            this.bgm.pause();
            SetCookie("AudioMuted", "muted");
        }

        return this.soundEnabled;
    }

    playBgm() {
        // Play if object exists, sound is enabled, and is not playing
        if (this.bgm && this.soundEnabled && !this.bgm.isPlaying) {
            //this.bgm.play();
        }
    }

    playClickSound() {
        if (this.soundEnabled) {
            this.clickSound.play();
        }
    }
}