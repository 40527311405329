import React, { Component } from "react";
import { MenuButton } from "./MenuButton";
import '../CSS/Buttons.css';

export class UIButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <MenuButton
                {...this.props}
                className="menu-button ui-button"
            >
                {this.props.children}
            </MenuButton>
        );
    }
}