import React, { Component } from 'react';
import { UIButton } from '../Buttons/UIButton';
import { InnerMenuButton } from '../Buttons/InnerMenuButton';
import '../CSS/MapButton.css';
import '../CSS/Buttons.css';

export class MapButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.opened = false;
        this.state = {
            opened: this.opened
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.opened = !this.opened;

        this.setState({
            opened: this.opened
        });
    }

    render() {
        return (
            <div className="map-button-container">
                <div
                    className="map-button-inner-container"
                    style={{
                        opacity: this.state.opened ? "1" : "0",
                        visibility: this.state.opened ? "visible" : "hidden"
                    }}
                >
                    <div className="map-button-inner-wrapper">
                        {
                            this.props.locations.map((e, i) =>
                                <InnerMenuButton key={i} onClick={() => window.Environment.selectCubemap(e[1], true)}>
                                    {e[0]}
                                </InnerMenuButton>
                            )
                        }
                    </div>
                </div>
                <UIButton onClick={this.toggle}>
                    &#10687; フロアガイド
                </UIButton>
            </div>
        );
    }
}