import React, { Component } from 'react';

export class Spinner extends Component
{
    constructor(props)
    {
        super(props);

        this.props = props;
    }

    render()
    {
        return (
            <div style={{
                visibility: this.props.visible ? "visible" : "hidden", opacity: this.props.visible ? 1 : 0, position: "absolute", left: "0", top: "0", width: "100%", height: "100%", display: "flex",
                justifyContent: "center", alignItems: "center", background: "rgba(0, 0, 0, 0.5)", borderRadius: "5px",
                transition: "visibility 200ms ease, opacity 200ms ease",
                ...this.props.style
            }}>
                <div style={{ pointerEvents: "none" }} className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
}
