import React, { Component } from "react";
import { MenuButton } from "./MenuButton";
import { MapButton } from "./MapButton";
import { SoundButton } from "./SoundButton";
import { FloorsMenu } from "./FloorsMenu";
import { UsernameButton } from "./UsernameButton";
import "../CSS/UI.css";

export class UI extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="ui-container ui-column-left">
        <div className="ui-column">
          {/*  <FloorsMenu
                        currentCubemap={this.props.currentCubemap}
                        floors={this.props.floors}
                    /> */}
          <div style={{ flexGrow: "1" }}></div>
          <MenuButton />
        </div>
        <div className="ui-column ui-column-right">
          <UsernameButton />
          <div style={{ flexGrow: "1" }}></div>
          <MapButton locations={this.props.mapLocations} />
        </div>
      </div>
    );
  }
}
