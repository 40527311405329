import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import * as THREE from 'three';
import { CubemapFace } from './CubemapFace';

// A cubemap is made of 6 faces
export class Cubemap extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        // Create mesh
        this.mesh = new THREE.Mesh();
        this.meshes = [];

        // Add this object to the parent
        this.props.parent.addMesh(this);
    }

    componentDidMount() {
        let faces = ['nx', 'ny', 'nz', 'px', 'py', 'pz'];

        // Once it has mounted, update the transformations of all its children in reverse
        for (let i = 0; i < 6; i++) {
            let face = new CubemapFace({
                parent: this,
                renderer: this.props.renderer,
                name: this.props.name,
                face: faces[i],
                currentCubemap: this.props.currentCubemap,
                cubemapTeleport: this.props.cubemapTeleport,
                loadOnStartup: this.props.loadLowResOnStartup,
                highRes: false
            });

            this.mesh.add(face.mesh);
            this.meshes.push(face);
        }

        for (let i = 0; i < 6; i++) {
            let face = new CubemapFace({
                parent: this,
                renderer: this.props.renderer,
                name: this.props.name,
                face: faces[i],
                currentCubemap: this.props.currentCubemap,
                cubemapTeleport: this.props.cubemapTeleport,
                loadOnStartup: this.props.loadHighResOnStartup,
                highRes: true
            });

            this.mesh.add(face.mesh);
            this.meshes.push(face);
        }

        // Faces second
        for (let i = 0; i < this.meshes.length; i++) {
            // Panels first
            for (let j = 0; j < this.meshes[i].meshes.length; j++) {
                this.meshes[i].meshes[j].updateTransformation(); // Panel
            }

            this.meshes[i].updateTransformation(); // Face
        }

        // Cubemap third
        this.updateTransformation(); // Cubemap
    }

    componentDidUpdate() {
        for (let i = 0; i < this.meshes.length; i++) {
            this.meshes[i].componentDidUpdate({
                currentCubemap: this.props.currentCubemap,
                cubemapTeleport: this.props.cubemapTeleport,
            });
        }
    }

    update(delta) {
        for (let i = 0; i < this.meshes.length; i++) {
            let mesh = this.meshes[i];
            mesh.update(delta);
        }
    }

    updateTransformation() {
        this.mesh.position.set(this.props.position.x, this.props.position.y, this.props.position.z);
    }

    render() {
        return <></>
    }
}