import React from 'react';
import { CSS3DComponent } from './CSS3DComponent';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import '../CSS/CSS3D.css';

export class ImageHover extends CSS3DComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        // Add a hover class to the main container
        this.divRef.current.classList.add('css3d-product-container');
    }

    onClick() {
        if (window.Environment.cameraMoved) {
            return;
        }

        window.Environment.showPopupProduct('fridge');
    }

    subrender() {
        return (
            <div
                ref="img"
                style={{
                    position: "relative",
                    width: `${this.props.scale.z}px`,
                    height: `${this.props.scale.y}px`
                }}
            >
                <div className="css3d-product-marker" >
                    ▼
                </div>
                <SVG
                    ref="svg"
                    src={this.props.url}
                    className="css3d-product-svg"
                    onClick={this.onClick}
                />
            </div>
        )
    }
}