import React, { Component } from 'react';
import * as THREE from 'three';
import { Vector3 } from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { isMobile } from 'react-device-detect';
import { CSS3DObject, CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { TextureManager } from './TextureManager';
import { ZoomPinchControl } from './ZoomPinchControl';

export class Renderer extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.addMesh = this.addMesh.bind(this);
        this.moveCameraTo = this.moveCameraTo.bind(this);
        this.zoomCamera = this.zoomCamera.bind(this);
        this.requestAnimate = this.requestAnimate.bind(this);
        this.animate = this.animate.bind(this);

        // Reference to the canvas
        this.canvasRef = React.createRef();

        // If we have to render or not
        this.renderFlag = true;

        // Create renderer
        this.renderer = new THREE.WebGLRenderer({ powerPreference: 'low-power' });
        this.renderer.setPixelRatio(isMobile ? window.devicePixelRatio * 0.7 : window.devicePixelRatio); // Lower for phones and pixel perfect for PCs
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.scene = new THREE.Scene();

        // Create CSS renderer
        this.cssRenderer = new CSS3DRenderer();
        this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
        this.cssRenderer.domElement.style.position = 'absolute';
        this.cssRenderer.domElement.style.top = '0';
        this.cssRenderer.domElement.style.left = '0';

        // Create camera
        this.cameraZoomInitial = 60;
        this.cameraZoomMin = 30;
        this.cameraZoomMax = 60;
        this.cameraZoomTarget = this.cameraZoomMin;
        this.camera = new THREE.PerspectiveCamera(this.cameraZoomInitial, window.innerWidth / window.innerHeight, 1, 99999);

        // Texture manager will send texture to the GPU one at a time
        this.textureManager = new TextureManager(this.renderer);
        this.scene.add(this.camera);
        this.camera.add(this.textureManager.mesh);
        this.textureManager.mesh.position.set(0, 0, -200);
        window.TextureManager = this.textureManager;

        // For updating 3D
        this.animate = this.animate.bind(this);
        this.meshes = [];
        this.clock = new THREE.Clock();
        this.delta = 0;

        // For moving camera
        this.controls = new OrbitControls(this.camera, this.cssRenderer.domElement);
        this.controls.enableZoom = true;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = isMobile ? 0.2 : 0.1;
        this.controls.rotateSpeedInitial = isMobile ? -0.4 : -0.2;
        this.controls.rotateSpeed = this.controls.rotateSpeedInitial;

        // Request animation when moving camera
        this.controls.addEventListener('change', this.requestAnimate);

        //controls.update() must be called after any manual changes to the camera's transform
        this.camera.position.set(this.props.currentCubemap.position.x, this.props.currentCubemap.position.y, this.props.currentCubemap.position.z);
        this.controls.target.copy(this.camera.position.add(new Vector3(0, 0, -1)));
        this.camera.position.set(this.props.currentCubemap.position.x, this.props.currentCubemap.position.y, this.props.currentCubemap.position.z);
        this.controls.update();

        // Zoom
        this.cssRenderer.domElement.addEventListener('wheel', function (event) {
            this.zoomCamera(event.deltaY / 200);
        }.bind(this), false);

        // Pinch zoom for phones
        this.zoomPinchControl = new ZoomPinchControl(this.cssRenderer.domElement, this.zoomCamera);

        // Resize things
        window.addEventListener('resize', function() {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();

            this.renderer.setPixelRatio(isMobile ? window.devicePixelRatio * 0.7 : window.devicePixelRatio); // Lower for phones and pixel perfect for PCs
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
            this.requestAnimate();
        }.bind(this), false);

        // Draw calls
        this.drawCalls = React.createRef();
    }

    zoomCamera(d) {
        this.camera.fov += d;
        this.camera.fov = Math.min(Math.max(this.camera.fov, this.cameraZoomMin), this.cameraZoomMax);
        this.camera.updateProjectionMatrix();

        this.controls.rotateSpeed = this.controls.rotateSpeedInitial * (this.camera.fov / this.cameraZoomInitial);
    }

    // To be used by children
    addMesh(e) {
        this.meshes.push(e);
        this.scene.add(e.mesh);
    }

    // To be used by children
    addCssComponent(e) {
        this.scene.add(e);
    }

    moveCameraTo(position) {
        let cameraDirection = new THREE.Vector3();
        this.camera.getWorldDirection(cameraDirection);

        this.camera.position.set(position.x, position.y, position.z);
        this.controls.target.copy(this.camera.position.add(cameraDirection));
        this.camera.position.set(position.x, position.y, position.z);
    }

    componentDidMount() {
        // Append renderer to DOM
        this.canvasRef.current.appendChild(this.renderer.domElement);

        // Append CSS renderer to DOM
        this.canvasRef.current.appendChild(this.cssRenderer.domElement);

        // Move camera to first cubemap
        this.moveCameraTo(this.props.currentCubemap.position);

        // Start updating 3D
        requestAnimationFrame(this.animate);
    }

    requestAnimate() {
        if (this.renderFlag) { // Already rendering
            return;
        }

        this.renderFlag = true;
        requestAnimationFrame(this.animate);
    }

    animate() {
        // Stop rendering until next request
        this.renderFlag = false;

        // Get delta
        this.delta = this.clock.getDelta();

        // Move camera
        if (this.props.cubemapTeleport) {
            this.moveCameraTo({
                x: this.props.currentCubemap.position.x,
                y: this.props.currentCubemap.position.y,
                z: this.props.currentCubemap.position.z
            });
        }
        else {
            this.moveCameraTo({
                x: this.camera.position.x * 0.9 + this.props.currentCubemap.position.x * 0.1,
                y: this.camera.position.y * 0.9 + this.props.currentCubemap.position.y * 0.1,
                z: this.camera.position.z * 0.9 + this.props.currentCubemap.position.z * 0.1
            });
        }

        // If camera is moving, ask to render again
        if (
            Math.abs(this.camera.position.x - this.props.currentCubemap.position.x) > 0.02 &&
            Math.abs(this.camera.position.y - this.props.currentCubemap.position.y) > 0.02 &&
            Math.abs(this.camera.position.z - this.props.currentCubemap.position.z) > 0.02
            ) {
            this.requestAnimate();
        }

        // Update logic of every object
        for (let i = 0; i < this.meshes.length; i++) {
            if (this.meshes[i].update) {
                this.meshes[i].update(this.delta);
            }
        }

        // Update controls
        this.controls.update();

        // Update frame
        this.renderer.render(this.scene, this.camera);
        this.cssRenderer.render(this.scene, this.camera);

        //this.drawCalls.current.innerHTML = this.renderer.info.render.calls
    }

    render() {
        return (
            <>
                <div ref={this.canvasRef}>
                {
                    React.Children.map(this.props.children, (child => React.cloneElement(child, { parent: this, renderer: this.renderer })))
                }
                </div>
                {
                    //<div
                    //    ref={this.drawCalls}
                    //    style={{
                    //    position: "absolute",
                    //    left: "20px",
                    //    top: "20px",
                    //    zIndex: "100",
                    //    background: "rgba(0, 0, 0, 0.5)",
                    //    fontSize: "1rem",
                    //    color: "white",
                    //    fontWeight: "bold",
                    //    padding: "0.5rem",
                    //}}>
                    //</div>
                }
            </>
        );
    }
}