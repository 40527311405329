import React, { Component } from "react";
import '../CSS/Buttons.css';
import { Button } from "./Button";

export class InnerMenuButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <div className="inner-menu-button-container">
                <Button
                    {...this.props}
                    className="menu-button inner-menu-button"
                >
                    {this.props.children}
                </Button>

            </div>
        );
    }
}