import React, { Component } from 'react';
import { LoginPage } from './LoginPage';
import { RegisterPage } from './RegisterPage';
import { CodePage } from './CodePage';
import { getCookie, eraseCookie } from '../Utils/Cookies'

import 'cross-fetch/polyfill';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Redirect, Route, Switch } from 'react-router';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import { AuthService } from './AuthService';

// Cognito functions that need to change the login state
export class Login extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.doSilentLogin = getCookie("RememberMe") === "true";
        console.log(this.doSilentLogin);

        this.state = {
            authenticated: this.doSilentLogin ? null : false // Don't render anything if it will try to silently login
        }

        this.SilentLogin = this.SilentLogin.bind(this);
        this.Login = this.Login.bind(this);
        this.SignUp = this.SignUp.bind(this);
        this.ConfirmCode = this.ConfirmCode.bind(this);
        this.ResendConfirmCode = this.ResendConfirmCode.bind(this);
        this.ForgotPassword = this.ForgotPassword.bind(this);
        this.DeleteUser = this.DeleteUser.bind(this);
    }

    componentDidMount() {
        if (this.doSilentLogin) {
            this.SilentLogin();
        }
    }

    SilentLogin() {
        // The user pool tokens are saved to local storage
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        // Retrieve the last authenticated user object
        var cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            // use the refresh token to retrieve new access tokens if they are expired
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log(err);
                    this.setState({
                        authenticated: false
                    });

                    return;
                }
                console.log('session validity: ' + session.isValid());
                //You should have a valid session here
                this.setState({
                    authenticated: true
                });
            }.bind(this));
        }
        else {
            this.setState({
                authenticated: false
            });
        }
    }

    SignUp(username, password, onSuccess, onFailure) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var attributeList = [];

        var dataEmail = {
            Name: 'email',
            Value: username,
        };

        var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

        attributeList.push(attributeEmail);

        userPool.signUp(username, password, attributeList, null, function (
            err,
            result
        ) {
            if (err) {
                console.log(err.code);
                console.log(err.message || JSON.stringify(err));

                let errorMessage = "登録できませんでした";

                if (err.code === "InvalidParameterException") {
                    errorMessage = "ユーザーネームまたはパスワードが違います";
                }

                onFailure(errorMessage);

                return;
            }
            //var cognitoUser = result.user;
            onSuccess();
        });
    }

    ConfirmCode(username, code, onSuccess, onFailure) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var userData = {
            Username: username,
            Pool: userPool,
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                console.log(err.code);
                console.log(err.message || JSON.stringify(err));

                let errorMessage = "エラー";

                if (err.code === "CodeMismatchException") {
                    errorMessage = "コードが正しくありません";
                }

                onFailure(errorMessage);
                return;
            }
            console.log('call result: ' + result);
            onSuccess();
        });
    }

    ResendConfirmCode(username, onSuccess, onFailure) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var userData = {
            Username: username,
            Pool: userPool,
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                console.log(err.code);
                console.log(err.message || JSON.stringify(err));

                let errorMessage = "エラー";
                onFailure(errorMessage);
                return;
            }
            console.log('call result: ' + result);
            onSuccess();
        });
    }

    ForgotPassword(username, onSuccess, onFailure) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var userData = {
            Username: username,
            Pool: userPool,
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function (result) {
                console.log(result);
                onSuccess();
            },
            onFailure: function (err) {
                console.log(err.code);
                console.log(err.message || JSON.stringify(err));

                let errorMessage = "エラー";
                onFailure(errorMessage);
            }
        });
    }

    ConfirmForgotPasswordCode(username, code, newPassword, onSuccess, onFailure) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var userData = {
            Username: username,
            Pool: userPool,
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess() {
                console.log('Password confirmed!');
                onSuccess();
            },
            onFailure(err) {
                console.log(err.code);
                console.log(err.message || JSON.stringify(err));

                let errorMessage = "エラー";
                onFailure(errorMessage);
            },
        });
    }

    Login(username, password, onSuccess, onFailure) {
        // Do login
        var authenticationData = {
            Username: username,
            Password: password,
        };

        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );

        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());
        var userData = {
            Username: username,
            Pool: userPool,
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                this.setState({
                    authenticated: true
                });

                onSuccess(result);

                AuthService.GetToken();

                // @fede: the user will not be using AWS services, no need to run code below

                // var accessToken = result.getAccessToken().getJwtToken();

                //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                //AWS.config.region = '<region>';

                //AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                //    IdentityPoolId: '...', // your identity pool id here
                //    Logins: {
                //        // Change the key below according to the specific region your user pool is in.
                //        'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>': result
                //            .getIdToken()
                //            .getJwtToken(),
                //    },
                //});

                ////refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                //AWS.config.credentials.refresh(error => {
                //    if (error) {
                //        console.error(error);
                //    } else {
                //        // Instantiate aws sdk service objects now that the credentials have been updated.
                //        // example: var s3 = new AWS.S3();
                //        console.log('Successfully logged!');
                //    }
                //});
            }.bind(this),

            onFailure: function (err) {
                console.log(err.code);
                console.log(err.message || JSON.stringify(err));

                let errorMessage = "ログインできませんでした";

                if (err.code === "InvalidParameterException") {
                    errorMessage = "ユーザーネームまたはパスワードが違います";
                }

                onFailure(errorMessage);
            }.bind(this),
        });
    }

    DeleteUser(onSuccess, onFailure) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(AuthService.GetUserPool());

        var cognitoUser = userPool.getCurrentUser();

        cognitoUser.getSession(function (err, session) {
            if (err) {
                console.log(err.message || JSON.stringify(err));
            } else {
                cognitoUser.deleteUser(function (err2, result) {
                    if (err2) {
                        console.log(err2.code);
                        console.log(err2.message || JSON.stringify(err2));

                        let errorMessage = "エラー";

                        onFailure(errorMessage);
                        return;
                    }
                    console.log('call result: ' + result);
                    onSuccess();
                });
            }
        });
    }

    render() {
        if (this.state.authenticated === null) { // Trying to silently login, render nothing
            return (<></>);
        }
        else if (this.state.authenticated) { // Logged in, render environment
            return (this.props.children);
        }
        else { // Not logged in, render login page
            return (
                <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: '100%',
                    height: '100%',
                    background: 'url(/textures/login_back.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: "center",
                    overflow: "hidden",
                }}>
                    <Switch>
                        <Route exact path='/' render={(props) => <LoginPage {...props} login={this} />} />
                        <Route path='/Register' render={(props) => <RegisterPage {...props} login={this} />} />
                        <Route path='/Code' render={(props) => <CodePage {...props} login={this} />} />
                        <Route path='/ForgotPassword' render={(props) => <ForgotPasswordPage {...props} login={this} />} />
                        <Route render={() => <Redirect to={`/?returnUrl=${window.location.pathname.substr(1)}`} />} />
                    </Switch>
                </div>
            );
        }
    }
}