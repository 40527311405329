export function SetCookie(cname, cvalue, exdays, exhours, exminutes, exseconds)
{
    var d = new Date();
    exdays = exdays === undefined ? 1 : exdays;
    exhours = exhours === undefined ? 24 : exhours;
    exminutes = exminutes === undefined ? 60 : exminutes;
    exseconds = exseconds === undefined ? 60 : exseconds;

    d.setTime(d.getTime() + (exdays * exhours * exminutes * exseconds * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function GetCookie(cname)
{
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++)
    {
        var c = ca[i];
        while (c.charAt(0) === ' ')
        {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0)
        {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}