import { isMobile } from 'react-device-detect';
import * as THREE from 'three';

// A cubemap panel is a plane with a texture
export class CubemapPanel {
    constructor(props) {
        this.props = props;

        this.load = this.load.bind(this);
        this.sendToGPU = this.sendToGPU.bind(this);

        // Show if it's the initial cubemap
        this.opacity = this.props.currentCubemap.name === this.props.name ? 1 : 0;

        // Create 3D model
        this.geometry = new THREE.PlaneGeometry(this.props.panelSize, this.props.panelSize, 1, 1);
        this.material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
            transparent: true,
            depthTest: false,
            opacity: this.opacity
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);

        // Load texture
        let path = '';
        if (isMobile) {
            if (this.props.highRes) {
                path = window.Environment.settings.textures_phone_high_path;
            }
            else {
                path = window.Environment.settings.textures_phone_low_path;
            }
        }
        else {
            if (this.props.highRes) {
                path = window.Environment.settings.textures_pc_high_path;
            }
            else {
                path = window.Environment.settings.textures_pc_low_path;
            }
        }

        this.url = window.Environment.GetAssetUrl(`${path}/${this.props.name}/${this.props.face}/${this.props.face}-${this.props.index}.jpg`);

        // Load textures at startup if it's specified
        if (this.props.loadOnStartup) {
            this.load();
            this.updateOrder();
        }
    }

    componentDidUpdate(props) {
        this.props.currentCubemap = props.currentCubemap;
        this.props.cubemapTeleport = props.cubemapTeleport;

        if (this.props.highRes) {
            if (this.props.name === this.props.currentCubemap.name) {
                this.load();
            }
            else {
                if (isMobile) {
                    this.unload();
                }
            }
        }
        else {
            if (this.props.currentCubemap.connections.includes(this.props.name)) {
                this.load();
            }
            else {
                //this.unload();
            }
        }

        this.updateOrder();
    }

    updateOrder() {
        if (this.props.name === this.props.currentCubemap.name) {
            this.mesh.renderOrder = this.props.highRes ? 11 : 10;
        }
        else {
            this.mesh.renderOrder = this.props.highRes ? 13 : 12;
        }
    }

    load() {
        // Already asked to load
        if (this.loaded) {
            return;
        }

        // Texture was already loaded
        if (this.material.map) {
            this.loaded = true;
            return;
        }

        this.loaded = true;

        window.TextureManager.loader.load(
            // resource URL
            this.url,

            // onLoad callback
            function (t) {
                this.texture = t;

                if (this.props.highRes) {
                    window.TextureManager.requestHighSendToGPU(this);
                }
                else {
                    window.TextureManager.requestLowSendToGPU(this);
                }
            }.bind(this),
            function () {
            }.bind(this),
            // onError callback
            function (err) {
                console.error('An error happened.');
                console.log(err);
            }.bind(this)
        );
    }

    unload() {
        this.loaded = false;
    }

    sendToGPU() {
        this.material.map = this.texture;
        this.material.needsUpdate = true;

        window.Environment.requestAnimate();
    }

    update(delta) {
        if (this.props.name === this.props.currentCubemap.name && this.material.map) {
            this.opacity = Math.min(this.opacity + delta * 3, 1);
        }
        else {
            if (this.props.cubemapTeleport) { // Hide immediately
                this.opacity = 0;
            }
            else {
                this.opacity = Math.max(this.opacity - delta, 0);
            }
        }

        // If opacity changed, request update (the first update will be when selecting cubemap or finish loading texture, so this will follow)
        if (this.opacity !== this.material.opacity) {
            window.Environment.requestAnimate();
        }

        this.material.opacity = this.opacity;

        this.mesh.visible = this.opacity > 0;

        if (this.props.highRes) {
            if (!this.loaded && this.material.map && this.opacity === 0) {
                this.texture.dispose();
                this.material.map = undefined;
                this.texture = undefined;
                this.material.needsUpdate = true;
            }
        }
    }

    updateTransformation() {
        this.mesh.position.set(this.props.position.x * this.props.panelSize, this.props.position.y * this.props.panelSize, 0);
    }
}