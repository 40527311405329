import React from 'react';
import { CSS3DComponent } from './CSS3DComponent';
import '../CSS/WaypointIcon.css';

export class ImagePanel extends CSS3DComponent {
    constructor(props) {
        super(props);
    }

    subrender() {
        return (
            <div style={{ width: `${this.props.scale.z}px`, height: `${this.props.scale.y}px` }}>
                <img
                    src={this.props.url}
                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                />
            </div>
        )
    }
}