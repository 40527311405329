import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DivLoginContainer } from './DivLoginContainer';
import { DivError } from './DivError';
import { DivLogin } from './DivLogin';
import { PrivacyPolicy } from './PrivacyPolicy';

export class RegisterPage extends Component
{
    constructor(props)
    {
        super(props);

        this.props = props;

        this.state = {
            step: 1,
            processing: false,
            errorShow: false
        }

        this.GoHome = this.GoHome.bind(this);
        this.Register = this.Register.bind(this);
        this.SendCode = this.SendCode.bind(this);
    }

    GoHome() {
        this.props.history.push("/");
    }

    async Register() {
        this.setState({
            processing: true,
            errorShow: false
        });

        let username = document.getElementById("input_email").value;
        let password = document.getElementById("input_password").value;
        let password_confirm = document.getElementById("input_confirm_password").value;

        if (password !== password_confirm) {
            this.setState({
                processing: false,
                errorShow: true,
                errorMessage: "パスワードが一致しません"
            });
            this.refs.register.scrollToTop();

            return;
        }

        this.props.login.SignUp(
            username,
            password,
            function (result) {
                this.setState({
                    step: 2,
                    processing: false
                });
            }.bind(this),
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage,
                });
                this.refs.register.scrollToTop();
            }.bind(this)
        );
    }

    SendCode() {
        this.setState({
            processing: true,
            errorShow: false
        });

        let username = document.getElementById("input_email").value;
        let code = document.getElementById("input_code").value;

        this.props.login.ConfirmCode(
            username,
            code,
            function (result) {
                this.setState({
                    step: 3,
                    processing: false
                });
            }.bind(this),
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage,
                });
                this.refs.sendcode.scrollToTop();
            }.bind(this)
        );
    }

    render()
    {
        return (
            <div className="register-event-title-container">
                <div className="register-event-title-fixed">
                    {window.AppSettings.event_title}
                </div>
                <DivLoginContainer right>
                    <DivLogin
                        ref="register"
                        title="ユーザー登録"
                        step={1}
                        currentstep={this.state.step}
                        processing={this.state.processing}
                        header={
                            <div className="register-event-title">
                                {window.AppSettings.event_title}
                            </div>
                        }
                    >
                        <DivError visible={this.state.errorShow} message={this.state.errorMessage} />

                        <div className="form-group">
                            <label htmlFor="input_email">会社名</label>
                            <input disabled={this.state.step !== 1 || this.state.processing} id="input_email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input_email">店舗名</label>
                            <input disabled={this.state.step !== 1 || this.state.processing} id="input_email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input_email">氏名</label>
                            <input disabled={this.state.step !== 1 || this.state.processing} id="input_email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input_email">役職</label>
                            <input disabled={this.state.step !== 1 || this.state.processing} id="input_email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input_email">メールアドレス</label>
                            <input disabled={this.state.step !== 1 || this.state.processing} id="input_email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div style={{ flexGrow: "1" }}>
                                    パスワード
                                </div>
                                <div style={{ fontSize: "85%" }}>
                                    6 文字以上必要です。
                                </div>
                            </div>
                            <input disabled={this.state.step !== 1 || this.state.processing} id="input_email" className="form-control" />
                        </div>
                        <label>来場予定日時</label>
                        <div class="row">
                            <div class="col-6 form-group">
                                <select className="form-select">
                                    <option selected></option>
                                    <option value="1">1日</option>
                                    <option value="2">2日</option>
                                    <option value="3">3日</option>
                                </select>
                            </div>
                            <div class="col-6 form-group">
                                <select className="form-select">
                                    <option selected></option>
                                    <option value="1">10:00</option>
                                    <option value="2">11:00</option>
                                    <option value="3">12:00</option>
                                </select>
                            </div>
                        </div>

                        <PrivacyPolicy onClickCancel={this.GoHome} onClickAccept={this.Register} acceptTitle="同意して送信" />
                    </DivLogin>

                    <DivLogin ref="sendcode" title="ユーザー登録" step={2} currentstep={this.state.step} processing={this.state.processing}>
                        <DivError visible={this.state.errorShow} message={this.state.errorMessage} />
                        <p className="login-description">
                            メールで受け取ったコードをここに入力してください
                        </p>
                        <br />
                        <div className="form-group">
                            <label htmlFor="input_code">コード</label>
                            <input disabled={this.state.step !== 2 || this.state.processing} id="input_code" className="form-control" />
                        </div>
                        <br />
                        <br />
                        <div className="form-group">
                            <button type="button" onClick={this.SendCode} className="btn btn-primary btn-login">送る</button>
                        </div>
                        <br />
                        <div className="text-center">
                            <Link className="login-clickable-link" to="/">ログインへ戻る</Link>
                        </div>
                    </DivLogin>

                    <DivLogin title="ユーザー登録" step={3} currentstep={this.state.step} processing={this.state.processing}>
                        <p className="login-description">
                            登録が完了しました。
                            <br />
                            今すぐログインできます。
                        </p>
                        <br />
                        <div className="text-center">
                            <Link className="login-clickable-link" to="/">ログインへ戻る</Link>
                        </div>
                    </DivLogin>
                </DivLoginContainer>
            </div>
        );
    }
}
