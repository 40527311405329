import React from 'react';
import { PopupContainer } from './PopupContainer';
import '../CSS/Sessions.css';
import { Component } from 'react';
import { Spinner } from '../OtherUI/Spinner';

export class PopupSessions extends PopupContainer {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            currentSessionIndex: 0
        }

        this.close = this.close.bind(this);
        this.selectContent = this.selectContent.bind(this);
    }

    selectContent(i) {
        this.setState({
            currentSessionIndex: i
        });
    }

    close() {
        window.Environment.audioManager.playClickSound();
        this.hide();
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    subrender(props) {
        if (props.sessions && props.sessions.length > 0) {
            let session = props.sessions[this.state.currentSessionIndex];

            return (
                <div className="sessions-container-main fade-in">
                    <div className="sessions-container">
                        <div className="sessions-container-inner">
                            <div className="session-page">
                                <div className="session-page-title">
                                    <div className="session-page-title-info">
                                        <div className="session-page-title-day-date">
                                            <div className="session-page-title-day">Day 1</div>
                                            <div className="session-page-title-date">２月２５日</div>
                                            <div className="session-page-title-dow">（土曜日）</div>
                                        </div>
                                        <div className="session-page-title-text">メインステージ　プレnoゼンテーション</div>
                                    </div>
                                    <div className="session-card-close" onClick={this.close}>
                                        <div className="session-card-close-x">
                                            <img src="textures/icons/close_x.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="session-card-container">
                                    <div className="session-card-images">
                                        {
                                            // Session cards
                                            props.sessions.map(function (data, i) {
                                                return <SessionCard key={i} data={data} onSelect={() => this.selectContent(i)} selected={this.state.currentSessionIndex === i} />;
                                            }.bind(this))
                                        }
                                    </div>
                                    <div className="session-card-info">
                                        <div className="session-card-info-img">
                                            <img src={session ? session.thumbnailUrl : ''} className="session-card-info-img-img" />
                                        </div>
                                        <div className="session-card-info-data-header-container">
                                            <div className="session-card-info-header">
                                                <div className="session-card-info-header-title">
                                                    {session ? session.title : ''}
                                                </div>
                                            </div>
                                            <div className="session-card-info-data">
                                                <div className="session-card-info-data-header">
                                                    <div className="session-card-info-header-dates">
                                                        { /* TODO: format these appropriately */}
                                                        {session ? `${session.timeStart.getMonth() + 1}月${session.timeStart.getDay()}日　${session.timeStart.getHours()}:${this.padTo2Digits(session.timeStart.getMinutes())} - ${session.timeEnd.getHours()}:${this.padTo2Digits(session.timeEnd.getMinutes())}` : ''}
                                                    </div>
                                                    <div className="session-card-info-header-speakers">
                                                        {session ? session.speakers : ''}
                                                    </div>
                                                    <div className="session-card-info-data-text">
                                                        {session ? session.description : ''}
                                                    </div>
                                                </div>
                                                <div className="session-card-info-data-buttons">
                                                    <div className="session-card-info-data-button session-card-info-data-buttons-bookmark">
                                                        ブックマーク
                                                    </div>
                                                    <div className=" session-card-info-data-button-divider"></div>
                                                    <div className="session-card-info-data-button session-card-info-data-buttons-nowplaying">
                                                        現在講演中
                                                    </div>
                                                    <div className="session-card-info-data-button session-card-info-data-buttons-watch">
                                                        視聴開始
                                                        <div className="session-card-play">
                                                            <div className="session-card-play-triangle">▶</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else if (props.sessions && props.sessions.length === 0) {
            return (
                <div className="sessions-container-main">
                    <div className="sessions-container">
                        <div className="sessions-container-inner">
                            <div className="session-page" style={{ position: "relative", height: "70%" }}>
                                <div className="session-page-title">
                                    <div className="session-page-title-info" style={{ opacity: "0" }}>
                                    </div>
                                    <div className="session-card-close" onClick={this.close}>
                                        <div className="session-card-close-x">
                                            <img src="textures/icons/close_x.png" />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%",
                                        zIndex: "500",
                                        transition: "opacity 1s ease-in-out, visibility 1s ease-in-out",
                                        pointerEvents: "none",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "100%",
                                            pointerEvents: "none",
                                        }}
                                    >
                                        <h1 style={{ color: "white" }}>No session data available</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="sessions-container-main">
                    <div className="sessions-container">
                        <div className="sessions-container-inner">
                            <div className="session-page" style={{ position: "relative", height: "70%" }}>
                                <div className="session-page-title">
                                    <div className="session-page-title-info" style={{opacity: "0"}}>
                                    </div>
                                    <div className="session-card-close" onClick={this.close}>
                                        <div className="session-card-close-x">
                                            <img src="textures/icons/close_x.png" />
                                        </div>
                                    </div>
                                </div>
                                <Spinner visible={true} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

class SessionCard extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    render() {
        return (
            <div className={`session-card ${this.props.selected ? 'selected' : ''}`} onClick={this.props.onSelect} style={{ pointerEvents: "auto" }}>
                <div className="session-card-time">
                    <div>
                        {this.props.data.timeStart.getHours()}:{this.padTo2Digits(this.props.data.timeStart.getMinutes())}
                    </div>
                    <div>
                        {this.props.data.timeEnd.getHours()}:{this.padTo2Digits(this.props.data.timeEnd.getMinutes())}
                    </div>
                </div>
                <div className="session-card-image">
                    <img className="session-card-img " src={this.props.data.thumbnailUrl} />
                    <div className="session-to-activate"></div>
                </div>
                <div className="session-card-desc">
                    <div className="session-to-activate"></div>
                    <div className="session-card-desc-text">
                        {this.props.data.title}
                    </div>
                </div>
                <div className="session-card-divider"></div>
            </div>
        );
    }
}