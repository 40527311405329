import React, { Component } from "react";
import { MenuButton } from "../Buttons/MenuButton";

export class FullscreenVideo extends Component
{
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            error: false,
            visible: this.props.visible
        }

        this.hide = this.hide.bind(this);

        this.video = React.createRef();
    }

    componentDidMount() {

        //this.video.current.video.onload = function () {
        //}

        // If video is not defined
        if (this.props.src === undefined) {
            window.Environment.setState({
                introVideoLoaded: true
            });

            return;
        }

        // Check if the video exists
        this.video.current.addEventListener('error', function (event) {
            // Video doesn't exist, hide and start app
            this.setState({
                error: true
            });

            window.Environment.setState({
                introVideoLoaded: true
            });
        }.bind(this))

        // After the video has preloaded, start the app
        this.video.current.addEventListener('loadeddata', function () {
            // Tell environment that the intro video is ready to play
            window.Environment.setState({
                introVideoLoaded: true
            });

            // Hide when it ends
            this.video.current.addEventListener('ended', this.hide, false);
        }.bind(this));
    }

    hide() {
        this.setState({
            visible: false
        });
    }

    render() {
        if (this.props.src === undefined || this.state.error) {
            return <></>;
        }
        else {
            return (
                <div
                    style={{
                        position: "fixed",
                        left: "0",
                        top: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "100",
                        transition: "0.7s linear opacity 0.4s, 0.7s linear visibility 0.4s",
                        opacity: this.state.visible ? "1" : "0",
                        visibility: this.state.visible ? "visible" : "hidden",
                        pointerEvents: this.state.visible ? "auto" : "none",
                        overflow: "hidden",
                    }}>
                    <video
                        ref={this.video}
                        width="100%"
                        height="100%"
                        autoPlay
                        playsInline
                        muted
                        style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            pointerEvents: "none",
                            background: "black",
                            objectFit: "cover",
                            pointerEvents: "none",
                            overflow: "hidden",
                        }}
                        src={this.props.src}
                    >
                    </video>
                    <MenuButton
                        style={{
                            position: "fixed",
                            right: "2rem",
                            bottom: "2rem",
                            minWidth: "160px",
                            pointerEvents: this.state.visible ? "auto" : "none",
                            transition: "0.5s transform ease-in-out 0.1s",
                            transform: this.state.visible ? "none" : "translateX(125%)"
                        }}
                        onClick={this.hide}
                    >
                        Skip
                    </MenuButton>
                </div>
            );
        }
    }
}
