import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import '../CSS/Buttons.css';

export class Button extends Component
{
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <button
                {...this.props}
                style={{
                    pointerEvents: "auto",
                    ...this.props.style
                }}
                onClick={() => {
                    if (isMobile) {
                        return;
                    }

                    if (window.Environment.cameraMoved) {
                        return;
                    }

                    if (this.props.onClick) {
                        window.Environment.audioManager.playClickSound();
                        this.props.onClick();
                    }
                }}
                onTouchEnd={() => {
                    if (this.props.onClick) {
                        window.Environment.audioManager.playClickSound();
                        this.props.onClick();
                    }
                }}
                onPointerDown={() => {
                    if (this.props.onPointerDown) {
                        window.Environment.audioManager.playClickSound();
                        this.props.onPointerDown();
                    }
                }}
            >
                {this.props.children}
            </button>
        );
    }
}
