import React, { Component } from "react";
import { Button } from "./Button";
import '../CSS/Buttons.css';

export class MenuButton extends Component
{
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <Button
                className="menu-button"
                {...this.props}
            >
                {this.props.children}
            </Button>
        );
    }
}
