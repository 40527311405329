import React, { Component } from 'react';

export class PopupContainer extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            visible: this.props.visible === undefined ? false : this.props.visible
        }

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    // To override in subclass
    subrender(props) {

    }

    // To override in subclass
    async onShow() {

    }

    // To override in subclass
    onHide() {

    }

    async show() {
        await this.setState({
            visible: true,
            fadeOut: true
        });

        // By waiting a little, we trigger the opacity transition
        setTimeout(function () {
            this.setState({
                fadeOut: false
            });

            this.onShow();
        }.bind(this), 1);
    }

    hide() {
        this.onHide();

        this.setState({
            fadeOut: true
        });

        setTimeout(function () {
            this.setState({
                visible: false,
            });
        }.bind(this), 250);
    }

    render() {
        if (this.state.visible) {
            return (
                <div style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    zIndex: "100",
                    opacity: this.state.fadeOut ? "0" : "1",
                    transition: "opacity 0.25s linear",
                }}>
                    {this.subrender(this.props)}
                </div>
            );
        }
        else {
            return <></>;
        }
    }
}