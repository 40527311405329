import React, { Component } from 'react';
import { Button } from '../../Buttons/Button';
import '../../CSS/InformationMenuPanel.css';

export class CloseButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <Button className="card-info-close" onClick={this.props.onClick}>
                <img className="card-close-img" src="textures/icons/close_x.png" />
            </Button>
        );
    }
}