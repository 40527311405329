export class Resources_API  {
    constructor() {
    }

    //-------------------------------------------------------------------
    // To override in subclass

    // Fetch all necessary data at startup
    async fetchResources() {
    }

    // Fetch all necessary sessions data
    async fetchSessions() {
    }

    // Fetch all necessary products data
    async fetchProducts(props) {
    }

    // Fetch a single elements while app is running
    // MUST return a ImagePoster instance
    async fetchImagePoster(props) {
    }

    // MUST return a VideoPoster instance
    async fetchVideoPoster(props) {
    }

    // MUST return a Session instance
    async fetchSession(props) {
    }

    //-------------------------------------------------------------------
}

// Data to expose fetched resources in a well-defined format for Environment.js
export class Resources_API_Data {
    constructor() {
        // Data is stored in dictionaries for easy access
        this.imagePosters = {};
        this.videoPosters = {};
        this.products = {};
    }

    addImagePoster(id, url) {
        this.imagePosters[id] = new ImagePoster(id, url);
    }

    addVideoPoster(id, url) {
        this.videoPosters[id] = new VideoPoster(id, url);
    }

    addProduct(id, name, images, colors, description) {
        this.products[id] = new Product(id, name, images, colors, description);
    }
}

class ImagePoster {
    constructor(id, url) {
        this.id = id;
        this.url = url;
    }
}

class VideoPoster {
    constructor(id, url) {
        this.id = id;
        this.url = url;
    }
}

// Data to expose fetched resources in a well-defined format for Environment.js
export class Sessions_API_Data {
    constructor() {
        // Data is stored in an array
        this.sessions = [];
    }

    addSession(id, timeStart, timeEnd, thumbnailUrl, title, internalTitle, description, videoUrl, speakers) {
        this.sessions.push(new Session(id, timeStart, timeEnd, thumbnailUrl, title, internalTitle, description, videoUrl, speakers));
    }
}

class Session {
    constructor(id, timeStart, timeEnd, thumbnailUrl, title, internalTitle, description, videoUrl, speakers) {
        this.id = id;
        this.timeStart = new Date(timeStart);
        this.timeEnd = new Date(timeEnd);
        this.thumbnailUrl = thumbnailUrl;
        this.title = title;
        this.internalTitle = internalTitle;
        this.description = description;
        this.videoUrl = videoUrl;
        this.speakers = speakers;
    }
}

class Product {
    constructor(id, name, images, colors, description) {
        this.id = id;
        this.name = name;
        this.images = images;
        this.colors = colors;
        this.description = description;
    }
}