import { AsyncScheduler, ConsoleLogger, DataMessage, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { AuthService } from "../../Login/AuthService";
import { Message, Messages_API_Data, VideoChat_API } from "../VideoChat_API";

export class BPZ_VideoChat_API extends VideoChat_API  {
    constructor() {
        super();

        this.dataMessageHandler = this.dataMessageHandler.bind(this);
        this.leave = this.leave.bind(this);

        this.apiUrl = "https://stg.chime-api.virtual-experience-space.com";
    }

    async join(meeting_title, onReceiveMessage, selfVideoTileElement, selfVideoOn) {
        const token = AuthService.GetToken();

        // Joing meeting
        let meetingResponse;

        await fetch(`${this.apiUrl}/join?` + new URLSearchParams({
            title: meeting_title,
            name: 'Fede', // TODO: change this
        }),
            {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': token,
                },
            })
            .then(res => res.json())
            .then((result) => {
                meetingResponse = result;
            })
            .catch(error => {
                meetingResponse = null;
            });

        // Cannot get response or cannot get info from response: error
        if (meetingResponse === null || meetingResponse.Info === undefined) {
            return {
                ok: false,
                errorMessage: 'No meeting available'
            };
        }
        else {
            this.meetingConfig = new MeetingSessionConfiguration(
                meetingResponse.Info.Meeting,
                meetingResponse.Info.Attendee
            );

            return this.initializeMeetingSession(this.meetingConfig, onReceiveMessage, selfVideoTileElement, selfVideoOn);
        }
    }

    async initializeMeetingSession(configuration, onReceiveMessage, selfVideoTileElement, selfVideoOn) {
        let logger = new ConsoleLogger("SDK", LogLevel.WARN);
        const deviceController = new DefaultDeviceController(logger);

        configuration.enableWebAudio = false;
        configuration.enableUnifiedPlanForChromiumBasedBrowsers = true;

        const meetingSession = new DefaultMeetingSession(
            configuration,
            logger,
            deviceController
        );
        this.meetingSession = meetingSession;

        this.audioVideo = this.meetingSession.audioVideo;
        const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
        const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();

        // Logging
        audioInputDevices.forEach((mediaDeviceInfo) => {
            console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
        });

        // TODO: enable audio 
        if (audioInputDevices.length > 0) {
            //await meetingSession.audioVideo.chooseAudioInputDevice(
            //    audioInputDevices[0].deviceId
            //);
        }

        if (audioOutputDevices.length > 0) {
            //await meetingSession.audioVideo.chooseAudioOutputDevice(
            //    audioOutputDevices[0].deviceId
            //);
        }

        if (videoInputDevices.length > 0) {
            await meetingSession.audioVideo.chooseVideoInputDevice(
                videoInputDevices[0].deviceId
            );
        }

        // TODO: bind audio
        //meetingSession.audioVideo.bindAudioElement(audioElement);

        const observer = {
            audioVideoDidStart: () => {
                console.log("chime started")
            },
            audioVideoDidStartConnecting: (reconnecting) => {
                console.log(`session connecting. reconnecting: ${reconnecting}`)
            },
            audioVideoDidStop: (sessionStatus) => {
                console.log(`session stopped from ${JSON.stringify(sessionStatus)}`)
            },
            videoTileDidUpdate: (tileState) => {
                console.log("video tile was updated");
                console.log(tileState);

                if (tileState.localTile) {
                    // if the video is turning on, bind it
                    if (selfVideoOn) {
                        if (tileState.boundVideoStream != null) {
                            if (tileState.boundVideoStream.active == true) {

                                // Add my video tile
                                this.meetingSession.audioVideo.bindVideoElement(
                                    tileState.tileId,
                                    selfVideoTileElement
                                );
                            }
                        }
                    }
                }
            },
            videoTileWasRemoved: (tileID) => {
                console.log("video Tile Was Removed: " + tileID)
            },
            contentShareDidStart: () => {
                console.log("content did start");
            },
            contentShareDidStop: () => {
                console.log("content did stop");
            },
        };

        meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('chat', function (dataMessage) {
            console.log(dataMessage);
            onReceiveMessage(new TextDecoder().decode(dataMessage.data));
        });
        meetingSession.audioVideo.addObserver(observer);
        meetingSession.audioVideo.addContentShareObserver(observer);

        meetingSession.audioVideo.startLocalVideoTile();
        meetingSession.audioVideo.start();

        return {
            ok: true
        };
    }

    leave() {
        if (this.meetingSession) {
            this.meetingSession.audioVideo.chooseVideoInputDevice(null);
            this.meetingSession.audioVideo.stopLocalVideoTile();
            // @fede: code breaks
            //this.meetingSession.audioVideo.stopVideoPreviewForVideoInput(previewVideoElement);
            this.meetingSession.audioVideo.stop()
        }
    }

    async fetchMessages() {
        // Formatted data to return
        let apiData = new Messages_API_Data();

        return apiData;
    }

    sendMessage(messageData, onSend) {
        let me = this;

        AsyncScheduler.nextTick(function () {
            const textToSend = messageData.message;

            me.meetingSession.audioVideo.realtimeSendDataMessage(
                'chat', //DemoMeetingApp.DATA_MESSAGE_TOPIC,
                textToSend,
                300_000 //DemoMeetingApp.DATA_MESSAGE_LIFETIME_MS
            );

            onSend(messageData);

            // @fede: leave this just in case for now, can be removed later
            // echo the message to the handler
            //this.dataMessageHandler(
            //    new DataMessage (
            //        Date.now(),
            //        'chat', //DemoMeetingApp.DATA_MESSAGE_TOPIC,
            //        new TextEncoder().encode(textToSend),
            //        this.meetingSession.configuration.credentials.attendeeId,
            //        this.meetingSession.configuration.credentials.externalUserId
            //    )
            //);
        });
    }

    dataMessageHandler(dataMessage) {
        // TODO: append message to html
        console.log(dataMessage);
    }

    startSelfCamera() {
        if (this.meetingSession && this.meetingSession.audioVideo) {
            this.meetingSession.audioVideo.startLocalVideoTile();
        }
    }

    stopSelfCamera() {
        if (this.meetingSession && this.meetingSession.audioVideo) {
            this.meetingSession.audioVideo.stopLocalVideoTile();
        }
    }
}