export class ZoomPinchControl  {
    constructor(el, zoomCamera) {
        this.zoomCamera = zoomCamera;

        this.evCache = new Array();
        this.prevDiff = -1;

        this.pointerdown_handler = this.pointerdown_handler.bind(this);
        this.pointermove_handler = this.pointermove_handler.bind(this);
        this.pointerup_handler = this.pointerup_handler.bind(this);
        this.remove_event = this.remove_event.bind(this);
        
        el.onpointerdown = this.pointerdown_handler;
        el.onpointermove = this.pointermove_handler;

        // Use same handler for pointer{up,cancel,out,leave} events since
        // the semantics for these events - in this app - are the same.
        el.onpointerup = this.pointerup_handler;
        el.onpointercancel = this.pointerup_handler;
        el.onpointerout = this.pointerup_handler;
        el.onpointerleave = this.pointerup_handler;
    }

    pointerdown_handler(ev) {
        this.evCache.push(ev);
    }

    pointermove_handler(ev) {
        // Find this event in the cache and update its record with this event
        for (let i = 0; i < this.evCache.length; i++) {
            if (ev.pointerId == this.evCache[i].pointerId) {
                this.evCache[i] = ev;
                break;
            }
        }

        // If two pointers are down, check for pinch gestures
        if (this.evCache.length == 2) {
            // Calculate the distance between the two pointers
            let curDiff = Math.abs(this.evCache[0].clientX - this.evCache[1].clientX);

            if (this.prevDiff > 0) {
                let distance = this.prevDiff - curDiff;
                this.zoomCamera(distance / 10);
            }

            // Cache the distance for the next move event
            this.prevDiff = curDiff;
        }
    }

    pointerup_handler(ev) {
        // Remove this pointer from the cache and reset the target's
        // background and border
        this.remove_event(ev);

        // If the number of pointers down is less than two then reset diff tracker
        if (this.evCache.length < 2) {
            this.prevDiff = -1;
        }
    }

    remove_event(ev) {
        // Remove this event from the target's cache
        for (let i = 0; i < this.evCache.length; i++) {
            if (this.evCache[i].pointerId == ev.pointerId) {
                this.evCache.splice(i, 1);
                break;
            }
        }
    }
}