import React from 'react';
import { Button } from '../../Buttons/Button';
import '../../CSS/InformationMenuPanel.css';
import * as THREE from 'three';
import { CSS3DComponent } from '../CSS3DComponent';
import { CloseButton } from './CloseButton';
import { DivContainer } from './DivContainer';

export class BoothMenuPanel extends CSS3DComponent {
    constructor(props) {
        super(props);

        this.state = {
            menu: 0
        }

        this.selectMenu = this.selectMenu.bind(this);
    }

    selectMenu(e) {
        this.setState({
            menu: e
        })
    }

    subrender() {
        // Make size independent of distance
        let distance = new THREE.Vector3(this.props.currentCubemap.position.x, this.props.currentCubemap.position.y, this.props.currentCubemap.position.z).distanceTo(new THREE.Vector3(this.props.position.x, this.props.position.y, this.props.position.z)) / 1000;

        return (
            <div name={this.props.key} style={{ position: "relative", transform: `scale(${distance})` }}>
                <DivContainer index={0} menu={this.state.menu} >
                    <Button className="css-text-sign-i-big" onClick={() => { this.selectMenu(1) }}>
                        <div className="css-text-sign-i-inner-big">
                            i
                        </div>
                    </Button>
                </DivContainer>

                <DivContainer index={1} menu={this.state.menu}>
                    <CloseButton onClick={() => { this.selectMenu(0) }} />
                    <div className="info-card card col-12">
                        <div className="card-header info-card-header">
                            <span className="card-header-text">INFORMATION</span>
                        </div>
                        <div className="card-body">
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" onClick={() => { window.Environment.showPopup("1") }}>商品詳細情報</Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" onClick={() => { window.Environment.showVideoPopup("https://ves-test.s3.ap-northeast-1.amazonaws.com/videos/batman2.mp4") }}>動画再生</Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" disabled>カタログダウンロード</Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button">コンタクト</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DivContainer>
            </div>
        )
    }
}