export class VideoChat_API  {
    constructor() {
        this.sendMessage = this.sendMessage.bind(this);
    }

    //-------------------------------------------------------------------
    // To override in subclass

    async fetchMessages() {
    }

    // Requires Message type
    async sendMessage(message) {
    }


    async join() {
    }

    //-------------------------------------------------------------------
}

export class Messages_API_Data {
    constructor() {
        this.messages = []; // Requires Message type
    }

    addMessage(message) {
        this.messages.push(message);
    }
}

export class Message {
    constructor(id, timeStamp, me, message) {
        this.id = id;
        this.timeStamp = new Date(timeStamp);
        this.me = me;
        this.message = message;
    }
}