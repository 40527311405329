import React, { Component } from 'react';

export class DivContainer extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <div
                onClick={this.props.onClick}
                style={{
                    position: this.props.menu === this.props.index ? "initial" : "absolute",
                    left: this.props.menu === this.props.index ? "initial" : "50%",
                    top: this.props.menu === this.props.index ? "initial" : "50%",
                    transform: this.props.menu === this.props.index ? "none" : "translate(-50%, -50%)",
                    transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
                    opacity: this.props.menu === this.props.index ? '1' : '0',
                    visibility: this.props.menu === this.props.index ? 'visible' : 'hidden',
                    pointerEvents: this.props.menu === this.props.index ? 'auto' : 'none',
                }}
            >
                {this.props.children}
            </div>
        );
    }
}