import React, { Component } from 'react';
import { Spinner } from './Spinner';

export class DivLogin extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.scrollToTop = this.scrollToTop.bind(this);
    }

    scrollToTop() {
        this.refs.scrollable.scrollTo(0, 0);
    }

    render() {
        return (
            <div
                className={`login-card-pivot-container ${this.props.className}`}
                style={{
                    transform: `translateX(${this.props.step === this.props.currentstep ? "0" : (this.props.step > this.props.currentstep ? "110%" : "-110%")})`,
                }}
            >
                <div className="login-card-pivot" >
                    <div ref="scrollable" className="login-card card card-offset">
                        {this.props.header}
                        <div className="login-card-padding">
                            <div className="card-header">
                                {this.props.title}
                            </div>
                            <div className="card-divider">
                                <div>
                                </div>
                            </div>
                            <div className="card-body login-card-body">
                                <div className="login-box">
                                    <section>
                                        {this.props.children}
                                    </section>
                                </div>
                            </div>
                            <Spinner visible={this.props.currentstep != this.props.step || this.props.step == this.props.step && this.props.processing} ref="spinner" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}