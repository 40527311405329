import React, { Component } from 'react';
import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';

export class CSS3DComponent extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        // Reference to the HTML component
        this.divRef = React.createRef();

        this.mesh = new THREE.Mesh(); // To use just for the "lookAt" function
    }

    componentDidMount() {
        // Create 3D object, set it's position, and add it to the renderer
        this.cssComponent = new CSS3DObject(this.divRef.current);
        this.cssComponent.rotation.set(this.props.rotation.x, this.props.rotation.y, this.props.rotation.z);
        this.cssComponent.position.set(this.props.position.x, this.props.position.y - 200, this.props.position.z);
        this.mesh.position.set(this.cssComponent.position.x, this.cssComponent.position.y, this.cssComponent.position.z);
        this.props.parent.addCssComponent(this.cssComponent);
    }

    componentDidUpdate() {
        // Look at cubemap if instructed to do so
        if (this.props.lookAtCubemap) {
            this.mesh.lookAt(new THREE.Vector3(this.props.currentCubemap.position.x, this.props.currentCubemap.position.y, this.props.currentCubemap.position.z));
            this.cssComponent.rotation.set(this.mesh.rotation.x, this.mesh.rotation.y, this.mesh.rotation.z);
        }
    }

    // To override in subclass
    subrender() {

    }

    render() {
        return (
            <div
                ref={this.divRef} style={{
                    transformStyle: 'preserve-3d'
                }} >
                <div
                    style={{
                        visibility: this.props.seenFrom.includes(this.props.currentCubemap.name) ? 'visible' : 'hidden',
                        opacity: this.props.seenFrom.includes(this.props.currentCubemap.name) ? '1' : '0',
                        transformStyle: 'preserve-3d',
                        transition: 'visibility 0.4s linear, opacity 0.4s linear'
                    }}
                    className={this.props.seenFrom.includes(this.props.currentCubemap.name) ? '' : 'unclickable'}
                >
                    {this.subrender()}
                </div>
            </div>
        );
    }
}