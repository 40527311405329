import { faBan, faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { UIButton } from '../Buttons/UIButton';
import '../CSS/Buttons.css';

export class UsernameButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <UIButton
                style={{position: "relative", pointerEvents: "none"}}
            >
                {/*<img*/}
                {/*    src={"https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/icons/sound_" + (this.state.soundEnabled ? "3" : "0") + ".png"}*/}
                {/*    className="ui-button-sound-icon"*/}
                {/*/>*/}
                <div style={{ position: "absolute", left: "0", top: "0", height: "100%" }}>
                    <img style={{ height: "100%", transform: "translateX(-80%)" }} src="/css/camera_ban.png" />
                </div>
                <div>
                    山田　太郎
                </div>
                <div style={{
                    fontSize: "0.8rem"
                }}>
                    博報堂プロダクツ
                </div>
            </UIButton>
        );
    }
}