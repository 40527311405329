import React from 'react';
import { Button } from '../../Buttons/Button';
import '../../CSS/InformationMenuPanel.css';
import { CSS3DComponent } from '../CSS3DComponent';
import { CloseButton } from './CloseButton';
import { DivContainer } from './DivContainer';

export class InformationMenuPanel extends CSS3DComponent {
    constructor(props) {
        super(props);

        this.state = {
            menu: 0
        }

        this.selectMenu = this.selectMenu.bind(this);
    }

    selectMenu(e) {
        this.setState({
            menu: e
        })
    }

    subrender() {
        return (
            <div style={{ position: "relative", transform: "scale(0.7) translateY(-60px)" }}>
                <DivContainer index={0} menu={this.state.menu} >
                    <div style={{ transform: "translateY(280px)" }}>
                        <Button className="css-text-sign-i-big" onClick={() => { this.selectMenu(1) }}>
                            <div className="css-text-sign-i-inner-big">
                                i
                            </div>
                        </Button>
                    </div>
                </DivContainer>

                <DivContainer index={1} menu={this.state.menu}>
                    <CloseButton onClick={() => { this.selectMenu(0) }} />
                    <div className="info-card card col-12">
                        <div className="card-header info-card-header">
                            <span className="card-header-text">インフォメーション</span>
                        </div>
                        <div className="card-body">
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" style={{ backgroundColor: "Cornsilk"}} onClick={window.Environment.makeReservation}>
                                        Reserve booth ID 63
                                    </Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" style={{ backgroundColor: "Cornsilk" }} onClick={window.Environment.showPopupVideoChat}>
                                        Start videocall for booth ID 63
                                    </Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" onClick={() => window.Environment.showPopupPDF('/pdf/sample.pdf')}>
                                        操作方法
                                    </Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" onClick={() => { this.selectMenu(2) }}>
                                        総合リンク
                                    </Button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <Button type="button" className="info-button" onClick={() => { this.selectMenu(3) }}>
                                        お問合せ
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DivContainer>

                <DivContainer index={2} menu={this.state.menu}>
                    <CloseButton onClick={() => { this.selectMenu(0) }} />
                    <div className="info-card card col-12">
                        <div className="card-header info-card-header">
                            <span id="card-header-text">WEB LINK</span>
                        </div>
                        <div className="card-body">
                            <div className="row info-space">
                                <div className="col-12">
                                    <button type="button" className="info-button" disabled>ホームページ</button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <button type="button" className="info-button" disabled>商品情報ページ</button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <button type="button" className="info-button" disabled>関連情報リンク集</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DivContainer>
                
                <DivContainer index={3} menu={this.state.menu}>
                    <CloseButton onClick={() => { this.selectMenu(0) }} />
                    <div className="info-card card col-12">
                        <div className="card-header info-card-header">
                            <span id="card-header-text">FAQ</span>
                        </div>
                        <div className="card-body">
                            <div className="row info-space">
                                <div className="col-12">
                                    <button type="button" className="info-button" disabled>FAQ（よくある質問）</button>
                                </div>
                            </div>
                            <div className="row info-space">
                                <div className="col-12">
                                    <button type="button" className="info-button" disabled>お問合せ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DivContainer>
            </div>
        )
    }
}