import React, { Component } from 'react';
import { PopupContainer } from './PopupContainer';
import '../CSS/PDF.css';
import { CloseButton } from './CloseButton';

export class PopupPDF extends PopupContainer {
    constructor(props) {
        super(props);
    }

    subrender() {
        return (
            <div className="pdf-container">
                <CloseButton onClick={this.hide} />
                <div className="pdf-background">
                    <iframe className="pdf-viewer" src={this.props.url}></iframe>
                </div>
            </div>
        );
    }
}