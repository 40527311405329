import React, { Component } from 'react';
import { Button } from '../Buttons/Button';
import '../CSS/Buttons.css';

export class CloseButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        return (
            <div
                style={{
                    width: "90%",
                    textAlign: "right",
                    padding: "5px 0",
                }}
            >
                <Button
                    onClick={this.props.onClick}
                    className="popup-close-button"
                >
                    <img src="textures/icons/close_x.png" />
                </Button>
            </div>
        );
    }
}