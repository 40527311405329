import React, { Component } from 'react';
import { PopupContainer } from './PopupContainer';
import { CloseButton } from './CloseButton';
import '../CSS/Product.css';
import { Button } from '../Buttons/Button';

export class PopupProduct extends PopupContainer {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            selectedIndex: 0
        }

        this.select = this.select.bind(this);
    }

    async onShow() {
        await this.setState({
                selectedIndex: 0
            });
    }

    select(i) {
        this.setState({
            selectedIndex: i
        });
    }

    subrender() {
        return (
            <div className="product-container">
                <div className="product-background">
                    <div className="product-inner-container">
                        <div className="product-inner-container-cell">
                            <div className="product-display">
                                {
                                    this.props?.data?.images && this.props.data.images.map((e, i) => <Product key={i} src={e} index={i} selectedIndex={this.state.selectedIndex} />)
                                }
                                <div className="product-display-colors-container">
                                    {
                                        this.props?.data?.colors && this.props.data.colors.map((e, i) => <ProductColor key={i} color={e} index={i} selectedIndex={this.state.selectedIndex} onClick={() => {this.select(i)}} />)
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="product-inner-container-cell">
                            <div className="product-details">
                                <div className="product-detail">
                                    タイプ・呼称
                                </div>
                                <div className="product-detail">
                                    型式
                                </div>
                                <div className="product-detail">
                                    カラー
                                </div>
                                <div className="product-detail">
                                    サイズ
                                </div>
                                <Button className="product-btn">
                                    おすすめのポイント
                                </Button>
                                <Button className="product-btn">
                                    ベンチマーク
                                </Button>
                                <div style={{flexGrow: "1"}}>
                                </div>
                                <Button className="product-btn-2" onClick={this.hide}>
                                    戻る
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Product extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let selected = this.props.selectedIndex === this.props.index;

        return (
            <img
                className={`product-display-img ${selected ? 'selected' : ''}`}
                src={this.props.src}
            />
        );
    }
}

class ProductColor extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let selected = this.props.selectedIndex === this.props.index;

        return (
            <div
                onClick={this.props.onClick}
                style={{
                    background: this.props.color
                }}
                className={`product-display-colors-container-color ${selected ? 'selected' : ''}`}
                src={this.props.src}
            />
        );
    }
}