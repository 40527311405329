import { isMobile } from 'react-device-detect';
import * as THREE from 'three';

export class TextureManager {
    constructor(renderer) {
        const INTERVAL_TIME_HIGH = 30;
        const INTERVAL_TIME_LOW = 5;

        this.requestsHigh = [];
        this.requestsLow = [];

        this.requestHighSendToGPU = this.requestHighSendToGPU.bind(this);
        this.requestLowSendToGPU = this.requestLowSendToGPU.bind(this);

        this.loader = new THREE.TextureLoader();

        // Have a cube behind everything rendered that will force to load all materials
        this.geometry = new THREE.BoxGeometry(0.0001, 0.0001, 0.0001); // Make very small, but not 0
        this.material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
            transparent: true,
            depthTest: false,
            opacity: 1
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.renderOrder = 1;

        setInterval(function () {
            if (this.requestsHigh.length > 0) {
                let e = this.requestsHigh.pop();
                e.sendToGPU();

                if (!isMobile) {
                    this.mesh.material = e.material;
                }
            }
        }.bind(this), INTERVAL_TIME_HIGH);

        setInterval(function () {
            if (this.requestsLow.length > 0) {
                let e = this.requestsLow.pop();
                e.sendToGPU();
            }
        }.bind(this), INTERVAL_TIME_LOW);
    }

    requestHighSendToGPU(e) {
        this.requestsHigh.push(e);
    }

    requestLowSendToGPU(e) {
        this.requestsLow.push(e);
    }
}