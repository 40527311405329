import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DivLogin } from './DivLogin';
import { DivError } from './DivError';
import { DivLoginContainer } from './DivLoginContainer';

export class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            step: 1,
            processing: false,
            errorShow: false
        }

        this.Recover = this.Recover.bind(this);
        this.SendCodeAndNewPassword = this.SendCodeAndNewPassword.bind(this);
    }

    async Recover() {
        this.setState({
            processing: true,
            errorShow: false
        });

        let username = document.getElementById("input_email").value;

        this.props.login.ForgotPassword(
            username,
            function (result) {
                this.setState({
                    step: 2,
                    processing: false,
                });
            }.bind(this),
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage,
                });
                this.refs.recover.scrollToTop();
            }.bind(this)
        );
    }

    SendCodeAndNewPassword() {
        this.setState({
            processing: true,
            errorShow: false
        });

        let username = document.getElementById("input_email").value;
        let password = document.getElementById("input_password").value;
        let password_confirm = document.getElementById("input_confirm_password").value;
        let code = document.getElementById("input_code").value;

        if (password !== password_confirm) {
            this.setState({
                processing: false,
                errorShow: true,
                errorMessage: "パスワードが一致しません"
            });
            this.refs.sendcode.scrollToTop();

            return;
        }

        this.props.login.ConfirmForgotPasswordCode(
            username,
            code,
            password,
            function (result) {
                this.setState({
                    step: 3,
                    processing: false,
                });
            }.bind(this),
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage
                });
                this.refs.sendcode.scrollToTop();
            }.bind(this)
        );
    }

    render() {
        return (
            <DivLoginContainer>
                <DivLogin ref="recover" title="FORGOT PASSWORD" step={1} currentstep={this.state.step} processing={this.state.processing}>
                    <DivError visible={this.state.errorShow} message={this.state.errorMessage} />
                    <div className="form-group">
                        <label htmlFor="input_email">メールアドレス</label>
                        <input disabled={this.state.step != 1 || this.state.processing} id="input_email" className="form-control" />
                        <span className="text-danger"></span>
                    </div>
                    <br />
                    <br />
                    <div className="form-group">
                        <button type="button" onClick={this.Recover} className="btn btn-primary btn-login">回復する</button>
                    </div>
                    <br />
                    <div className="text-center">
                        <Link className="login-clickable-link" to="/">ログインへ戻る</Link>
                    </div>
                </DivLogin>

                <DivLogin ref="sendcode" title="FORGOT PASSWORD" step={2} currentstep={this.state.step} processing={this.state.processing}>
                    <DivError visible={this.state.errorShow} message={this.state.errorMessage} />
                    <p className="login-description">
                        メールで受け取ったコードをここに入力してください
                    </p>
                    <br />
                    <div className="form-group">
                        <label htmlFor="input_code">コード</label>
                        <input disabled={this.state.step != 2 || this.state.processing} id="input_code" className="form-control" />
                    </div>
                    <br />
                    <div className="form-group">
                        <label htmlFor="input_password">パスワード</label>
                        <input disabled={this.state.step != 2 || this.state.processing} id="input_password" type="password" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input_confirm_password">パスワードを確認してください</label>
                        <input disabled={this.state.step != 2 || this.state.processing} id="input_confirm_password" type="password" className="form-control" />
                    </div>
                    <br />
                    <br />
                    <div className="form-group">
                        <button type="button" onClick={this.SendCodeAndNewPassword} className="btn btn-primary btn-login">送る</button>
                    </div>
                    <br />
                    <div className="text-center">
                        <Link className="login-clickable-link" to="/">ログインへ戻る</Link>
                    </div>
                </DivLogin>

                <DivLogin title="FORGOT PASSWORD" step={3} currentstep={this.state.step} processing={this.state.processing}>
                    <p className="login-description">
                        パスワードが正常に変更されました。
                    </p>
                    <br />
                    <div className="text-center">
                        <Link className="login-clickable-link" to="/">ログインへ戻る</Link>
                    </div>
                </DivLogin>
            </DivLoginContainer>
        );
    }
}