import React, { Component } from 'react';
import { Route } from 'react-router';
import { Environment } from './NewEngine/Environment';
import { Login } from './Login/Login';

export default class App extends Component
{
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            render: false
        }
    }

    async componentDidMount() {
        // Get global app settings
        window.AppSettings = await fetch('/app_settings.json')
            .then(response => response.json());

        // Start the app after fetching the settings
        this.setState({
            render: true
        });
    }

    render() {
        if (this.state.render) {
            return (
                <Login>
                    <Route exact path='/' component={Environment} />
                </Login>
            );
        }
        else {
            return (
                <></>
            );
        }
    }
}