import React, { Component } from 'react';
import { UIButton } from '../Buttons/UIButton';
import '../CSS/MenuButton.css';
import '../CSS/Buttons.css';

export class MenuButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.opened = false;
        this.state = {
            opened: this.opened
        }

        this.showPopupSessions = this.showPopupSessions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
    }

    showPopupSessions() {
        window.Environment.showPopupSessions();
        this.close();
    }

    toggle() {
        this.opened = !this.opened;

        this.setState({
            opened: this.opened
        });
    }

    close() {
        this.opened = false;

        this.setState({
            opened: false
        });
    }

    render() {
        return (
            <div className="menu-button-container">
                <div
                    className="menu-button-inner-container"
                    style={{
                        opacity: this.state.opened ? "1" : "0",
                        visibility: this.state.opened ? "visible" : "hidden"
                    }}
                >
                    <UIButton style={{ marginBottom: "15px" }} onClick={this.showPopupSessions}>
                        イベント情報
                    </UIButton>
                    <UIButton style={{ marginBottom: "15px" }}>
                        操作方法
                    </UIButton>
                    <UIButton style={{ marginBottom: "15px" }}>
                        サウンド設定
                    </UIButton>
                    <UIButton style={{ marginBottom: "15px" }} onClick={window.Environment.logOut}>
                        ログアウト
                    </UIButton>
                </div>
                <UIButton onClick={this.toggle}>
                    &equiv; メニュー
                </UIButton>
            </div>
        );
    }
}