import React, { Component } from "react";
import * as THREE from "three";
import { AuthService } from "../Login/AuthService";
import { WaypointIcon } from "./CSS3D/WaypointIcon";
import { Cubemap } from "./Cubemaps/Cubemap";
import { ImagePanel } from "./CSS3D/ImagePanel";
import { Renderer } from "./Rendering/Renderer";
import { FullscreenVideo } from "./Video/FullscreenVideo";
import NoImage from "./CSS/no-image.png";
import { VideoPanel } from "./CSS3D/VideoPanel";
import { InformationMenuPanel } from "./CSS3D/Menus/InformationMenuPanel";
import { BoothMenuPanel } from "./CSS3D/Menus/BoothMenuPanel";
import { PopupBooth } from "./Popups/PopupBooth";
import { PopupVideo } from "./Popups/PopupVideo";
import { UI } from "./UI/UI";
import { PopupSessions } from "./Popups/PopupSessions";
import { AudioManager } from "./Audio/AudioManager";
import { FullscreenSpinner } from "./OtherUI/FullscreenSpinner";
import { BPZ_API } from "../API/CustomAPI/BPZ_API";
import { PopupVideoChat } from "./Popups/PopupVideoChat";
import { PopupPDF } from "./Popups/PopupPDF";
import { ImageHover } from "./CSS3D/ImageHover";
import { BPZ_VideoChat_API } from "../API/CustomAPI/BPZ_VideoChat_API";
import { PopupProduct } from "./Popups/PopupProduct";

export class Environment extends Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      introVideoLoaded: false,
      dataLoaded: false,
      currentCubemap: null,
      popupVideoUrl: "",
      loggingOut: false,
      sessions: null,
      cubemapTeleport: false,
      productData: null,
      pdfUrl: "",
    };

    this.selectCubemap = this.selectCubemap.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.showPopupSessions = this.showPopupSessions.bind(this);
    this.showPopupVideoChat = this.showPopupVideoChat.bind(this);
    this.showPopupPDF = this.showPopupPDF.bind(this);
    this.logOut = this.logOut.bind(this);
    this.requestAnimate = this.requestAnimate.bind(this);
    this.makeReservation = this.makeReservation.bind(this);

    // Make this a global object
    window.Environment = this;

    // Initial cubemap
    this.initialCubemapIndex = 2;

    // Intro video
    this.introVideo = React.createRef();

    // Data
    this.data = null;
    this.cubemaps = [];
    this.cubemapsDict = {};
    this.waypoints = [];
    this.popupRefs = {};
    this.popupVideoRef = React.createRef();
    this.popupSessionsRef = React.createRef();
    this.popupVideoChatRef = React.createRef();
    this.popupPDFRef = React.createRef();
    this.popupProductRef = React.createRef();
    this.rendererRef = React.createRef();
    this.api = null;
    this.videoChatApi = null;

    // Mouse
    this.cameraMoved = false;

    // Audio settings
    this.audioManager = new AudioManager();
  }

  async componentDidMount() {
    //Load environment json
    await this.loadData();

    // Set handedness
    this.setHandedness();

    // Create cubemaps
    this.createCubemaps();

    // Create popup refs
    this.createPopupRefs();

    // When clicking window the first time music should start
    document.addEventListener("pointerdown", function () {
      window.Environment.audioManager.playBgm();
    });

    this.setState({
      dataLoaded: true,
    });
  }

  async loadData(name) {
    this.settings = await fetch("/environment_settings.json").then((response) => response.json());

    // Make some values ints
    this.settings.num_panels_pc_high = parseInt(this.settings.num_panels_pc_high);
    this.settings.num_panels_pc_low = parseInt(this.settings.num_panels_pc_low);
    this.settings.num_panels_phone_high = parseInt(this.settings.num_panels_phone_high);
    this.settings.num_panels_phone_low = parseInt(this.settings.num_panels_phone_low);

    // TODO: handle error if configuration json file is wrong or cannot be fetched

    this.data = await fetch(`${this.settings.s3_url}/${this.settings.environment_path}.json`).then((response) => response.json());

    // TODO: handle error if environment json file is wrong or cannot be fetched

    // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
    // RESTful API: replace with the API you want to use.
    this.api = new BPZ_API(); // <- Replace this
    this.videoChatApi = new BPZ_VideoChat_API(); // <- Replace this

    this.apiResources = await this.api.fetchResources();
    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

    // TODO: handle error if API json file is wrong or cannot be fetched

    // Init audio (will start playing bgm if possible)
    this.audioManager.init(this.settings.bgm_url, this.settings.sound_click_url);

    // Add some hardcoded for now
    // TODO: add this externally
    this.apiResources.addImagePoster("booth_title_1", "https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/booth_titles/booth_title_placeholder.png");
    this.apiResources.addImagePoster("booth_title_2", "https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/booth_titles/booth_title_placeholder.png");
    this.apiResources.addImagePoster("presentation_1", "https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/presentation/presentation_placeholder.png");
    this.apiResources.addImagePoster("presentation_2", "https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/presentation/presentation_placeholder.png");
    this.apiResources.addImagePoster(
      "presentation_main",
      "https://ves-test.s3.ap-northeast-1.amazonaws.com/textures/presentation/presentation_main_placeholder.jpg"
    );
    this.apiResources.addImagePoster("batmobile_hover", "/api_example/batmobile_hover.svg");
  }

  setHandedness() {
    // Read environment file and change positions and rotations based on if it's Unreal or Unity

    // Swap spheres y/z positions if UE4
    if (this.data.environment.handedness === "UE4") {
      for (let i = 0; i < this.data.environment.spheres.length; i++) {
        let sphere = this.data.environment.spheres[i];

        sphere.position = this.calculatePositionBasedOnHeadness(this.data.environment.handedness, sphere.position);
      }
    }

    // Set image panels rotation if Unity or UE4
    // Swap spheres y/z positions if UE4
    for (let i = 0; i < this.data.environment.image_panels.length; i++) {
      let panel = this.data.environment.image_panels[i];

      panel.angle = this.calculateRotationBasedOnHeadness(this.data.environment.handedness, panel.angle);
      panel.position = this.calculatePositionBasedOnHeadness(this.data.environment.handedness, panel.position);
    }

    // Set video panels rotation if Unity or UE4
    // Swap spheres y/z positions if UE4
    for (let i = 0; i < this.data.environment.video_panels.length; i++) {
      let panel = this.data.environment.video_panels[i];

      panel.angle = this.calculateRotationBasedOnHeadness(this.data.environment.handedness, panel.angle);
      panel.position = this.calculatePositionBasedOnHeadness(this.data.environment.handedness, panel.position);
    }

    // Set video panels rotation if Unity or UE4
    // Swap spheres y/z positions if UE4
    for (let i = 0; i < this.data.environment.floating_menu.length; i++) {
      let panel = this.data.environment.floating_menu[i];

      panel.angle = this.calculateRotationBasedOnHeadness(this.data.environment.handedness, panel.angle);
      panel.position = this.calculatePositionBasedOnHeadness(this.data.environment.handedness, panel.position);
    }
  }

  createCubemaps() {
    let initialCubemap = this.data.environment.spheres[this.initialCubemapIndex];

    // Read environment file
    for (let i = 0; i < this.data.environment.spheres.length; i++) {
      let sphere = this.data.environment.spheres[i];

      // Load all textures for the first cubemap
      if (sphere.name === initialCubemap.name) {
        sphere.loadLowResOnStartup = true;
        sphere.loadHighResOnStartup = true;
      }

      // Load low res textures for near cubemaps and the ones from the map
      let isInMap = this.settings.map_locations.some((r) => sphere.name === r[1]);

      if (isInMap || sphere.connections.includes(initialCubemap.name)) {
        sphere.loadLowResOnStartup = true;
      }

      // Save cubemaps in a dictionary based on name
      this.cubemapsDict[sphere.name] = sphere;
    }

    this.setState({
      currentCubemap: initialCubemap,
    });
  }

  createPopupRefs() {
    this.popupRefs = {};

    for (let i = 0; i < 1; i++) {
      this.popupRefs["1"] = React.createRef();
    }
  }

  // Return a path using the specified S3 and environment paths from the configuration file
  GetAssetUrl(path) {
    return `${this.settings.s3_url}/${this.settings.environment_path}/${path}`;
  }

  async selectCubemap(name, teleport) {
    await this.setState({
      currentCubemap: this.cubemapsDict[name],
      cubemapTeleport: teleport,
    });

    this.rendererRef.current.requestAnimate();
  }

  requestAnimate() {
    this.rendererRef.current.requestAnimate();
  }

  showPopup(name) {
    this.popupRefs[name].current.show();
  }

  async showPopupSessions() {
    await this.setState({
      sessions: null,
    });

    this.popupSessionsRef.current.show();

    let data = await this.api.fetchSessions();

    await this.setState({
      sessions: data?.sessions,
    });
  }

  showPopupVideoChat() {
    this.popupVideoChatRef.current.show();
  }

  showPopupPDF(url) {
    this.setState({
      pdfUrl: url,
    });

    this.popupPDFRef.current.show();
  }

  showPopupProduct(id) {
    console.log(this.apiResources.products[id]);
    this.setState({
      productData: this.apiResources.products[id],
    });

    this.popupProductRef.current.show();
  }

  showVideoPopup(url) {
    this.setState({
      popupVideoUrl: url,
    });

    this.popupVideoRef.current.show();
  }

  closeVideoPopup() {
    this.popupVideoRef.current.hide();
  }

  calculateRotationBasedOnHeadness(handedness, angle) {
    let rotation = new THREE.Vector3(THREE.MathUtils.degToRad(angle.x), THREE.MathUtils.degToRad(90 - angle.y), THREE.MathUtils.degToRad(angle.z));
    if (handedness === "UE4") {
      if (angle.z !== 0 && angle.y !== 0) {
        // TODO RJW@SCS - add the X rotation for UE4
        let zRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, THREE.MathUtils.degToRad(180 + angle.z), 0, "XYZ"), true);
        let yRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(0 - angle.y), 0, 0, "XYZ"), true);
        let quatzy = zRotation.multiply(yRotation);
        let euler = new THREE.Euler().setFromQuaternion(quatzy, "XYZ");
        rotation = euler.toVector3();
      } else if (angle.z !== 0) {
        rotation = new THREE.Vector3(0, THREE.MathUtils.degToRad(90 - angle.z), 0); //new THREE.Euler(0, THREE.MathUtils.degToRad(ss.angle.z), 0);
      }
    }
    return rotation;
  }

  calculatePositionBasedOnHeadness(handedness, position) {
    if (handedness === "UE4") {
      return { x: position.x, y: position.z, z: position.y };
    } else {
      return position;
    }
  }

  logOut() {
    this.setState({
      loggingOut: true,
    });

    AuthService.Logout();
  }

  makeReservation() {
    this.api.makeReservation(63);
  }

  render() {
    // Render only when we are ready
    return (
      <div
        style={{
          position: "relative",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
        }}
        onPointerDown={() => {
          window.Environment.cameraMoved = false;
          window.Environment.pointerDown = true;
          window.Environment.cameraMovedCount = 0;
        }}
        onPointerMove={() => {
          if (window.Environment.pointerDown) {
            window.Environment.cameraMovedCount++;

            if (window.Environment.cameraMovedCount > 5) {
              window.Environment.cameraMoved = true;
            }
          }
        }}
        onPointerUp={() => {
          window.Environment.pointerDown = false;
        }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}>
          {this.state.dataLoaded && this.state.introVideoLoaded ? (
            <Renderer
              ref={this.rendererRef}
              currentCubemap={this.state.currentCubemap}
              cubemapTeleport={this.state.cubemapTeleport}>
              {
                // Cubemaps
                this.data.environment.spheres.map(
                  function (sphere, i) {
                    return (
                      <Cubemap
                        key={i}
                        name={sphere.name}
                        position={sphere.position}
                        currentCubemap={this.state.currentCubemap}
                        cubemapTeleport={this.state.cubemapTeleport}
                        loadLowResOnStartup={sphere.loadLowResOnStartup}
                        loadHighResOnStartup={sphere.loadHighResOnStartup}
                      />
                    );
                  }.bind(this)
                )
              }
              {
                // Waypoints
                this.data.environment.spheres.map(
                  function (sphere, i) {
                    return (
                      <WaypointIcon
                        key={i}
                        name={sphere.name}
                        position={sphere.position}
                        rotation={{ x: Math.PI / 2, y: 0, z: 0 }}
                        currentCubemap={this.state.currentCubemap}
                        seenFrom={sphere.connections}
                      />
                    );
                  }.bind(this)
                )
              }
              {
                // Wall posters
                this.data.environment.image_panels.map(
                  function (panel, i) {
                    if (this.apiResources.imagePosters[panel.image_id] === undefined) {
                      return <></>;
                    } else {
                      return (
                        <ImagePanel
                          id={panel.image_id}
                          key={i}
                          name={panel.name}
                          position={panel.position}
                          rotation={panel.angle}
                          scale={panel.scale}
                          url={this.apiResources.imagePosters[panel.image_id].url}
                          currentCubemap={this.state.currentCubemap}
                          seenFrom={panel.seen_from}
                        />
                      );
                    }
                  }.bind(this)
                )
              }
              {/*               {
                // Video panels
                this.data.environment.video_panels.map(
                  function (panel, i) {
                    if (this.apiResources.videoPosters[panel.video_id] === undefined) {
                      return <></>;
                    } else {
                      return (
                        <VideoPanel
                          id={panel.video_id}
                          key={i}
                          name={panel.name}
                          position={panel.position}
                          rotation={panel.angle}
                          scale={panel.scale}
                          url={this.apiResources.videoPosters[panel.video_id].url}
                          currentCubemap={this.state.currentCubemap}
                          seenFrom={panel.seen_from}
                        />
                      );
                    }
                  }.bind(this)
                )
              } */}
              {
                // Menus
                this.data.environment.floating_menu.map(
                  function (panel, i) {
                    // TODO: add more data here based on the booth id
                    let props = {
                      key: i,
                      name: panel.name,
                      position: panel.position,
                      rotation: panel.angle,
                      scale: panel.scale,
                      currentCubemap: this.state.currentCubemap,
                      seenFrom: panel.seen_from,
                      lookAtCubemap: true, // Make menus always look at the camera after a transition to a cubemap
                    };

                    switch (panel.name) {
                      case "BP_FloatingMenu6":
                        return <InformationMenuPanel {...props} />;
                      case "BP_FloatingMenu9":
                        return <BoothMenuPanel {...props} />;
                      default:
                        return <></>;
                    }
                  }.bind(this)
                )
              }
              {
                // 3D Hover
                this.data.environment.image_panels.map(
                  function (panel, i) {
                    // TODO: add more data here based on the booth id
                    let props = {
                      id: panel.image_id,
                      key: i,
                      name: panel.name,
                      position: panel.position,
                      rotation: panel.angle,
                      scale: 1,
                      // url: this.apiResources.imagePosters[panel.image_id].url,
                      currentCubemap: this.state.currentCubemap,
                      seenFrom: panel.seen_from,
                      lookAtCubemap: true, // Make menus always look at the camera after a transition to a cubemap
                    };

                    switch (panel.name) {
                      case "batmobile_hover":
                        return <ImageHover {...props} />;
                      default:
                        return <></>;
                    }
                  }.bind(this)
                )
              }
              <UI
                currentCubemap={this.state.currentCubemap}
                mapLocations={this.settings.map_locations}
                floors={this.settings.floors}
              />
              <PopupBooth ref={this.popupRefs["1"]} />
              <PopupVideo
                ref={this.popupVideoRef}
                url={this.state.popupVideoUrl}
              />
              <PopupSessions
                ref={this.popupSessionsRef}
                sessions={this.state.sessions}
              />
              <PopupVideoChat
                ref={this.popupVideoChatRef}
                api={this.api}
                videoChatApi={this.videoChatApi}
              />
              <PopupPDF
                ref={this.popupPDFRef}
                url={this.state.pdfUrl}
              />
              <PopupProduct
                ref={this.popupProductRef}
                data={this.state.productData}
              />
            </Renderer>
          ) : (
            <></>
          )}
        </div>
        {this.state.dataLoaded ? (
          <FullscreenVideo
            ref={this.introVideo}
            src={this.settings.intro_video_url}
            visible={true}
          />
        ) : (
          <></>
        )}
        <FullscreenSpinner visible={!(this.state.dataLoaded && this.state.introVideoLoaded) || this.state.loggingOut} />
      </div>
    );
  }
}
