import { isMobile } from 'react-device-detect';
import * as THREE from 'three';
import { CubemapPanel } from './CubemapPanel';

// A cubemap face is made of 3x3 cubemap panels
export class CubemapFace {
    constructor(props) {
        this.props = props;

        // Find number of panels
        this.numPanels = 0;
        if (isMobile) {
            if (this.props.highRes) {
                this.numPanels = window.Environment.settings.num_panels_phone_high;
            }
            else {
                this.numPanels = window.Environment.settings.num_panels_phone_low;
            }
        }
        else {
            if (this.props.highRes) {
                this.numPanels = window.Environment.settings.num_panels_pc_high;
            }
            else {
                this.numPanels = window.Environment.settings.num_panels_pc_low;
            }
        }

        // Constants
        const OFFSET = (this.numPanels - 1) / 2;
        this.panelSize = parseInt(5000 / this.numPanels);
        this.faceSize = this.numPanels * this.panelSize;

        // Create mesh
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.meshes = [];

        let index = 0;
        for (let i = 0; i < this.numPanels; i++) {
            for (let j = 0; j < this.numPanels; j++) {
                let panel = new CubemapPanel({
                    renderer: this.props.renderer,
                    index: index,
                    position: { x: j - OFFSET, y: -i + OFFSET },
                    name: this.props.name,
                    face: this.props.face,
                    parent: this,
                    panelSize: this.panelSize,
                    currentCubemap: this.props.currentCubemap,
                    cubemapTeleport: this.props.cubemapTeleport,
                    loadOnStartup: this.props.loadOnStartup,
                    highRes: this.props.highRes
                });

                this.mesh.add(panel.mesh);
                this.meshes.push(panel);

                index++;
            }
        }
    }

    componentDidUpdate(props) {
        for (let i = 0; i < this.meshes.length; i++) {
            this.meshes[i].componentDidUpdate(props);
        }
    }

    updateTransformation() {
        // Move and rotate this based on face
        switch (this.props.face) {
            case 'px':
                this.mesh.rotation.set(0, -Math.PI / 2, 0);
                this.mesh.position.set(this.faceSize / 2, 0, 0);
                break;
            case 'nx':
                this.mesh.rotation.set(0, Math.PI / 2, 0);
                this.mesh.position.set(-this.faceSize / 2, 0, 0);
                break;
            case 'py':
                this.mesh.rotation.set(Math.PI / 2, 0, Math.PI / 2);
                this.mesh.position.set(0, this.faceSize / 2, 0);
                break;
            case 'ny':
                this.mesh.rotation.set(-Math.PI / 2, 0, -Math.PI / 2);
                this.mesh.position.set(0, -this.faceSize / 2, 0);
                break;
            case 'pz':
                this.mesh.rotation.set(0, Math.PI, 0);
                this.mesh.position.set(0, 0, this.faceSize / 2);
                break;
            case 'nz':
                this.mesh.position.set(0, 0, -this.faceSize / 2);
                break;
        }
    }

    update(delta) {
        for (let i = 0; i < this.meshes.length; i++) {
            let mesh = this.meshes[i];
            mesh.update(delta);
        }
    }
}