import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DivError } from './DivError';
import { DivLoginContainer } from './DivLoginContainer';
import { DivLogin } from './DivLogin';
import { setCookie, eraseCookie, getCookie } from '../Utils/Cookies'
import { PrivacyPolicy } from './PrivacyPolicy';
import PinInput from 'react-pin-input';

export class LoginPage extends Component
{
    constructor(props)
    {
        super(props);

        this.props = props;

        this.state = {
            step: 1,
            processing: false,
            errorShow: false,
            rememberMe: getCookie("RememberMe") === "true"
        }

        this.Login = this.Login.bind(this);
        this.RememberMe = this.RememberMe.bind(this);
    }

    Login() {
        this.setState({
            processing: true,
            errorShow: false,
        });

        let username = document.getElementById("input_email").value;
        let password = document.getElementById("input_password").value;

        this.props.login.Login(
            username,
            password,
            function (result) {
            },
            function (errorMessage) {
                this.setState({
                    processing: false,
                    errorShow: true,
                    errorMessage: errorMessage,
                });
                this.refs.login.scrollToTop();
            }.bind(this)
        );
    }

    RememberMe(event) {
        if (event.target.checked) {
            setCookie("RememberMe", "true", 30);
        }
        else {
            eraseCookie("RememberMe");
        }

        this.setState({
            rememberMe: event.target.checked
        });
    }

    render()
    {
        return (
            <DivLoginContainer>
                <DivLogin ref="login" title="ログイン" step={1} currentstep={this.state.step} processing={this.state.processing}>
                    <DivError visible={this.state.errorShow} message={this.state.errorMessage} />
                    <div className="form-group">
                        <label htmlFor="input_email">メールアドレス</label>
                        <input id="input_email" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input_password">パスワード</label>
                        <input id="input_password" type="password" className="form-control" />
                    </div>
                    <div className="form-group" style={{textAlign: "right"}}>
                        <label htmlFor="input_remember_me" style={{ marginRight: "1.5rem" }}>ログイン情報を保存する</label>
                        <input id="input_remember_me" type="checkbox" className="form-check-input" checked={this.state.rememberMe} onChange={this.RememberMe} />
                    </div>

                    <PrivacyPolicy acceptTitle="同意してログイン" onClickAccept={() => { this.setState({step: 2})}} warning="＊Wi-Fi 環境での使用を推奨" />

                    <div className="form-group checkbox mt-2 mb-0">
                        <div>
                            <Link className="login-clickable-link-small" to="/ForgotPassword">パスワードをお忘れですか？</Link>
                        </div>
                        <div>
                            <Link className="login-clickable-link-small" to="/Code">確認コードを再送する</Link>
                        </div>
                        <div>
                            <Link className="login-clickable-link-small" to="/Register">登録する</Link>
                        </div>
                    </div>
                </DivLogin>

                <DivLogin ref="login" title="ログイン" step={2} currentstep={this.state.step} processing={this.state.processing} className="login-code">
                    <DivError visible={this.state.errorShow} message={this.state.errorMessage} />

                    <label style={{ marginRight: "1.5rem" }}>認証コード</label>
                    <PinInput
                        length={6}
                        initialValue=""
                        onChange={(value, index) => { }}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: '10px 0' }}
                        inputStyle={{ background: "white", borderColor: '#ccc', height: "70px" }}
                        inputFocusStyle={{ borderColor: '#eee' }}
                        onComplete={(value, index) => { }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <div className="form-group text-center mb-0">
                        <button type="button" onClick={this.Login} className="btn btn-primary btn-login">ログイン</button>
                    </div>
                </DivLogin>
            </DivLoginContainer>
        );
    }
}
